import React from 'react';
import FileUploadModal from '../../atoms/FileUploadModal';
import { deleteFile, uploadFile } from '../../../http/file-storage';

class FileAttachment extends React.Component {
    getFiles = () => {
        const { fileUploadID, files } = this.props;
        return files[fileUploadID] || [];
    };

    uploadFile = async file => {
        const { fileUploadID, files } = this.props;
        const fileID = await uploadFile(file);
        files[fileUploadID] = files[fileUploadID] || [];
        files[fileUploadID].push({
            id: fileID,
            name: file.name,
            contentType: file.type,
            size: file.size,
        });
        await this.props.onFilesChange(files);
    };

    deleteFile = async (fileId) => {
        await deleteFile(fileId);
        const { fileUploadID, files } = this.props;
        const fileIndex = files[fileUploadID].findIndex(f => f.id === fileId);
        files[fileUploadID].splice(fileIndex, 1);
        await this.props.onFilesChange(files);
    }

    render () {
        const { fileUpload, renderTrigger } = this.props;
		if (fileUpload) {
            const files = this.getFiles();
            return <FileUploadModal
                files={files}
                uploadFile={this.uploadFile}
                deleteFile={this.deleteFile}
                renderTrigger={renderTrigger}
            />;
        }
        return null;
    }
}

export default FileAttachment;
