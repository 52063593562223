import React, { useState, useEffect, useMemo } from 'react';
import { toast }                               from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import set from 'lodash.set';
import { fetchFlow, sendReminder, changeEmail } from 'http/esignatur';
import getEmailRegexp from 'util/getEmailRegexp';
import withUserData from 'util/withUserData';
import FieldGroup from 'design/atoms/FieldGroup';
import { userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import { isFile } from '../../../util/files';
import { isAccountant, isAdmin, isVirksomhed } from 'util/userMethods';
import { isFlowLockedEsignatur } from './FlowStatus';
import SigneeStatus, { signeeHasSigned, SigneeStateStarted } from './SigneeStatus';
import FlowHeader, { LegacyFlowStatus } from './FlowHeader';
import ReminderButton from './RemindButton';
import FileuploadButton from '../../atoms/FileuploadButton';
import ColoredText from '../../atoms/ColoredText';
import { 
    Button, 
    Form, 
    Icon, 
    Input, 
    Label, 
    List, 
    Segment, 
    Table, 
    Transition, 
    Loader, 
    Modal, 
    Grid,
    Popup, 
} from 'semantic-ui-react';
import { prepareDownloadLink, uploadFile, deleteFile } from '../../../http/file-storage';

import styles from './Esignatur.module.scss';

const Esignatur = ({
    error,
    disabled,
    onSubmit,
    internalPdfId,
    docGenFactID,
    productStatus,
    esignaturSignFlowId,
    reloadProductData,
    userData,
    secondaryDocuments,
    defaultSendInternalReportToSigning,
    defaultSendTaxbookletToSigning,
}) => {
    const fieldsDisabled = disabled;

    const defaultAttachedSecondaryDocumentIndices = new Set();
    if (defaultSendTaxbookletToSigning) {
        secondaryDocuments.forEach((_, idx) => defaultAttachedSecondaryDocumentIndices.add(idx));
    }

    const [includeInternalReport, setIncludeInternalReport] = useState(defaultSendInternalReportToSigning);  
    const [signees, setSignees] = useState([]);                           // er den lokale kopi af signFlow.signees. Kan ændre sig (ved user input). Ved klik på knappen gemmes signFlow til mongo
    const [hasNonUniqueSignees, setHasNonUniqueSignees] = useState(true); // all signess must be unique. Blocks "Sign"-button
    const [attachedSections, setAttachedSections] = useState(new Set());  // attach section print PDFs to the sign flow
    const [attachedSecondaryDocumentIndices, setAttachedSecondaryDocumentIndices] = useState(defaultAttachedSecondaryDocumentIndices);
    const [invalidEmails, setInvalidEmails] = useState(new Set());
    const [badEmails, setBadEmails] = useState(new Set());
    const [flow, setFlow] = useState({});
    const [loadingFlow, setLoadingFlow] = useState(false);
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const [attachedDocuments, setAttachedDocuments] = useState([]);
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(-1);
    const [newEmail, setNewEmail] = useState("");
    const [newEmailInvalid, setNewEmailInvalid] = useState(false);

    useEffect(() => {
        const fetchEsigFlow = async () => {
            if (esignaturSignFlowId !== "") {
                const fetchedFlow = await fetchFlow(esignaturSignFlowId);
            setFlow(fetchedFlow);
            setLoadingFlow(false);
            }
        };
        fetchEsigFlow(esignaturSignFlowId)
        return () => setFlow({});
    },[esignaturSignFlowId]);

    useEffect(() => {
        if (!flow) return ;
        if (!!flow.signees) {
            setSignees(flow.signees);
        }

        // means that the flow is created in esignatur
        if (!!flow.flowId) {
            setIncludeInternalReport(flow.includeInternalReport);
            setAttachedSections(new Set(flow.attachedSections));
            setAttachedSecondaryDocumentIndices(new Set(flow.attachedSecondaryDocumentIndices));
        }

        if (!!flow.attachedDocuments && (flow.status !== "" && "Cancelled")) { // Dont load old attached documents, we only store them to compare if a revoke is possible
            setAttachedDocuments(flow.attachedDocuments);
        } else {
            setAttachedDocuments([]);
        }
        if (!!flow.flowId && !flow.attachedDocuments) setAttachedDocuments([]);
    }, [flow]);

    // counts number of occurances
    const occurancesReducer = (accu, cur) => ({ ...accu, [cur]: (accu[cur] || 0) + 1 });

    // uniqueness:
    // all (email, name, and title)-tuples must be unique
    useEffect(() => {
        const occurrences = signees
            .map(signee => `${signee.name}#${signee.title}#${signee.email}`)
            .reduce(occurancesReducer, {});
        const hasNonUniques = Object.values(occurrences).some(count => count > 1);
        setHasNonUniqueSignees(hasNonUniques);
    }, [signees]);

    // invalid emails:
    // can be found in the flow after sign flow validation
    useEffect(() => {
        const invalidEmails = new Set(flow?.validationResult?.invalidEmails);
        setInvalidEmails(invalidEmails);
    }, [flow]);

    // invalid new emails:
    // can be found in the flow after sign flow validation
    useEffect(() => {
        const isNewEmailInvalid = async () => {
            try {
                // check emails (not empty, regex)
                const emailRegex = new RegExp(getEmailRegexp());
                const emailInvalid = !newEmail || !emailRegex.test(newEmail);
                if (emailInvalid) return setNewEmailInvalid(true);
                return setNewEmailInvalid(false);
            } catch (err) {
                console.error("Calc form validity failed:", err);
            }
            return setNewEmailInvalid(false);
        }
        isNewEmailInvalid(newEmail)
    }, [newEmail]);

    // bad emails:
    // looks for emails of signees that doesn't support TLS
    useEffect(() => {
        const badEmails = new Set();
        signees.forEach(signee => {
            invalidEmails.has(signee.email) && badEmails.add(signee.email);
        });
        setBadEmails(badEmails);
    }, [signees, invalidEmails]);

    // Handles onChange events from form fields.
    // See React "controlled components"
    // React does handle nested state well. Therefore we do a deep object copy via json.
    // Args:
    // - stateObj: Eg. signees
    // - setterFun: Eg. setSignees()
    // - path1: Eg. "[0]"
    // - path2: Eg. "email"
    // - value: New value of the nested state property (eg. "signees[0].email")
    const handleChange = (stateObj, setterFun, path1="") => {
        return (path2, value) => {
            const path = `${path1}${path2}`
            let newObj = JSON.parse(JSON.stringify(stateObj));
            set(newObj, path, value);
            setterFun(newObj);
        }
    }

    // Handles onChange events from form fields.
    // See React "controlled components"
    // React does handle nested state well. Therefore we do a deep object copy via json.
    // Args:
    // - stateObj: Eg. signees
    // - setterFun: Eg. setSignees()
    // - path1: Eg. "[0]"
    // - path2: Eg. "email"
    // - value: New value of the nested state property (eg. "signees[0].email")
    const handleChangeFile = (stateObj, setterFun, path1="") => {
        return (path2, value) => {
            const path = `${path1}${path2}`
            let newObj = [...stateObj];
            set(newObj, path, value);
            setterFun(newObj);
        }
    }

    const prepareFirstDownloadLink = () => {
        const out = document.createElement('a');
        out.rel = 'noopener noreferrer';
        out.target = '_blank';
        return out;
    };

    let downloadLink = prepareFirstDownloadLink();

    const renderTaxReturnOption = () => {
        const reportSectionSlug = 'taxq';
        const isChecked = attachedSections.has(reportSectionSlug);

        return (
            <Form.Group inline>
                { renderFormLabel('Medsend selvangivelse til godkendelse?') }
                <Form.Checkbox
                    label='Ja'
                    checked={isChecked}
                    disabled={disabled}
                    onChange={() => {
                        const updatedAttachedSections = new Set([...attachedSections]);
                        if (isChecked) {
                            updatedAttachedSections.delete(reportSectionSlug);
                        } else {
                            updatedAttachedSections.add(reportSectionSlug);
                        }
                        setAttachedSections(updatedAttachedSections);
                    }}
                />
            </Form.Group>
        );
    };

    const renderFormLabel = (text) => {
        return (
            <label style={{ opacity: fieldsDisabled ? 0.25 : 1 }}>
                {text}
            </label>
        );
    };

    const renderPrintOptions = () => {
        return (
            <Form.Group inline>
                {renderFormLabel('Medsend dokumenter til underskrift')}

                {internalPdfId && (
                    <Form.Checkbox
                        label='Internt regnskab'
                        checked={includeInternalReport}
                        disabled={disabled}
                        onChange={ (_, target) => setIncludeInternalReport(target.checked)}
                    />
                )}
                
                {(secondaryDocuments || []).map(({ documentMetadata }, documentIndex) => {
                    if (!documentMetadata) return null;

                    return (
                        <Form.Checkbox
                            label={documentMetadata.title}
                            checked={attachedSecondaryDocumentIndices.has(documentIndex)}
                            disabled={disabled}
                            onChange={(_, { checked }) => {
                                const newChoices = new Set([...attachedSecondaryDocumentIndices]);
                                checked ? newChoices.add(documentIndex) : newChoices.delete(documentIndex);
                                setAttachedSecondaryDocumentIndices(newChoices);
                            }}
                        />
                    );
                })}
            </Form.Group>
        );
    }

    const downloadClicked = async (signedFileId) => {
        const pdf = await prepareDownloadLinkFunc(signedFileId);
        if (!pdf) {
            return;
        }

        document.body.appendChild(downloadLink);
		downloadLink.href = pdf;
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const prepareDownloadLinkFunc = async (signedFileId) => {
		try {
			const link = await prepareDownloadLink(signedFileId, "attachment");
			return link;
		} catch (err) {
			console.error("Error", err);
		}
	};

    const handleFileDelete = async idx => {
        const attachedDocumentsForDeletion = attachedDocuments[idx]
        if (!!attachedDocumentsForDeletion.fileId) {
            await deleteFile(attachedDocumentsForDeletion.fileId)
        }
        if (!!attachedDocumentsForDeletion.signedFileId) {
            await deleteFile(attachedDocumentsForDeletion.signedFileId)
        }
        let newAttachedDocuments = [...attachedDocuments.slice(0, idx), ...attachedDocuments.slice(idx + 1)];
        return setAttachedDocuments([...newAttachedDocuments]);
    }

    const handleFileUpload = async file => {
        if (!isFile(file)) {
            return;
        }
        
        let newAttachedDocuments = attachedDocuments;
        newAttachedDocuments.push({file: file, includeRevisor: true, sign: false});
        return setAttachedDocuments([...newAttachedDocuments]);
    }

    const handleSendReminder = async (signeeId) => {
        if ((signeeId === null)  || !flow?.flowId) {
            return;
        }
        try {
            await sendReminder(flow.flowId, signeeId)
            if (esignaturSignFlowId !== "") { 
                const fetchedFlow = await fetchFlow(esignaturSignFlowId);
                setFlow(fetchedFlow);
            }
            toast.success('Ny påmindelse sendt');
        } catch {
            toast.error('Gensend fejlede');
        }
        
        return 
    }

    const handleChangeEmail = async (signeeId, signeeEmail, newSigneeEmail) => {
        if ((signeeId === null) || (signeeEmail === null) || (newSigneeEmail === null) || !flow?.flowId) {
            return;
        }
        try {
            await changeEmail(flow.flowId, signeeId, signeeEmail, newSigneeEmail)
            if (esignaturSignFlowId !== "") { 
                const fetchedFlow = await fetchFlow(esignaturSignFlowId);
                setFlow(fetchedFlow);
            }
            setNewEmail("")
            toast.success('Emailen er blevet ændret');
        } catch {
            toast.error('Ændring af email fejlede');
        }
        
        return setOpenChangeEmailModal(-1)
    }

    const renderDocumentRow = (file, idx, changeHandler) => {
        return <Table.Row key={!!file?.file?.title ? `${file.file.title}-${idx}` : `${file.filename}-${idx}`}>
            <Table.Cell>
                {!!file?.file?.name ? file.file.name : file.filename}
            </Table.Cell>
            <Table.Cell>
                { renderSignGroupButtons(file.includeRevisor, changeHandler) }
            </Table.Cell>
            <Table.Cell collapsing>
                { renderSignTypeButtons(file.sign, changeHandler) }
            </Table.Cell>
            <Table.Cell collapsing>
                { !isFlowLockedEsignatur(flow?.status) ? renderFileButtons(idx) : renderDownloadButton(file?.signedFileId, file?.fileId)}
            </Table.Cell>
        </Table.Row>
    }

    const renderDocumentMenu = () => {
        if (attachedDocuments.length === 0) {
            return <>
                <div className={styles.left}>
                    {
                        (!isFlowLockedEsignatur(flow?.status) && attachedDocuments.length !== 10) && 
                        <FileuploadButton
                        accept={"pdf"}
                        onChange={handleFileUpload}
                        clearFileAfterSelection={true}
                        disabled={disabled}
                        trigger={<Button
                            primary
                            content={"Vedhæft dokument(er)"}
                            />
                        }
                    />
                    }
                </div>
            </> 
        } 
        
        const documents = attachedDocuments.map( (file, idx) => {
            return renderDocumentRow(file, idx, handleChangeFile(attachedDocuments, setAttachedDocuments, `[${idx}]`))
        });

        return <>
            <div className={styles.left}>
                {
                    ( !disabled && !isFlowLockedEsignatur(flow?.status) && attachedDocuments.length !== 10) && 
                    <FileuploadButton
                    accept={"pdf"}
                    onChange={handleFileUpload}
                    clearFileAfterSelection={true}
                    trigger={<Button
                        primary
                        content={"Vedhæft dokument(er)"}
                        />
                    }
                    />
                }
            </div>
            <Table>
                {documents}
            </Table>
        </> 
    }

    const renderAdditionalDocumentsPicker = () => {
        if ((( !isFlowLockedEsignatur(flow?.status) && !disabled))) {
            return <Segment basic className="buttonSegment">
                <Grid equal columns={2}>
                    <Grid.Column textAlign="left" style={{padding: "0px"}}>
                        <ColoredText link iconPosition='left' color={"green"} icon={'upload'} content={'Vedhæft yderligere dokumenter'} onClick={() => setDocumentModalOpen(true)}/>
                        { attachedDocuments.length === 10 ? <p>Du kan ikke vedhæfte flere dokumenter. </p> : <></>}
                    </Grid.Column>  
                     <Grid.Column textAlign="right" style={{padding: "0px"}}>
                        { attachedDocuments.length > 0 ? <p>Du har vedhæftet {attachedDocuments.length} ud af 10 ekstra dokumenter. </p> : <></>}
                    </Grid.Column>  
                </Grid>
                <Modal
                    onClose={() => setDocumentModalOpen(false)}
                    onOpen={() => setDocumentModalOpen(true)}
                    open={documentModalOpen}
                >
                    <Modal.Header>Vedhæft yderligere dokumenter</Modal.Header>
                    <Modal.Content>
                            { renderDocumentMenu() }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button 
                            primary
                            onClick={() => setDocumentModalOpen(false)}
                            content='Luk'
                        />
                    </Modal.Actions>
                </Modal>
            </Segment>
        } else if (flow?.status === "Completed" || flow?.status === "Active") {
            if (attachedDocuments.length > 0) {
                return <Segment basic className="buttonSegment">
                    <Grid equal columns={2}>
                        <Grid.Column textAlign="left" style={{padding: "0px"}}>
                            <ColoredText link color={"green"} icon={'upload'} content={'Vedhæftede dokumenter '} onClick={() => setDocumentModalOpen(true)}/>
                        </Grid.Column>  
                        <Grid.Column textAlign="right" style={{padding: "0px"}}>
                        </Grid.Column>  
                    </Grid>
                    <Modal
                        onClose={() => setDocumentModalOpen(false)}
                        onOpen={() => setDocumentModalOpen(true)}
                        open={documentModalOpen}
                    >
                        <Modal.Header>Vedhæftede dokumenter</Modal.Header>
                        <Modal.Content>
                            { renderDocumentMenu() }
                        </Modal.Content>
                        <Modal.Actions>
                            <Button 
                                primary
                                onClick={() => setDocumentModalOpen(false)}
                                content='Luk'
                            />
                        </Modal.Actions>
                    </Modal>
                </Segment>
            } else {
                return <></>
            }
        }
            
        return <Segment basic className="buttonSegment">
            <Grid equal columns={2}>
                <Grid.Column textAlign="left" style={{padding: "0px"}}>
                    <ColoredText disabled={disabled} iconPosition='left' link color={"grey"} icon={'upload'} content={'Vedhæft yderligere dokumenter'} onClick={() => setDocumentModalOpen(true)}/>
                    { attachedDocuments.length === 10 ? <p>Du kan ikke vedhæfte flere dokumenter. </p> : <></>}
                </Grid.Column>  
                <Grid.Column textAlign="right" style={{padding: "0px"}}>
                    { attachedDocuments.length > 0 ? <p>Du har vedhæftet {attachedDocuments.length} ud af 10 ekstra dokumenter. </p> : <></>}
                </Grid.Column>  
            </Grid>
        </Segment>
    }

    const renderSignees = () => {
        if (!signees || signees.length === 0) return ;
        // map of signee-obj => UI compoennt (with change handler)
        const m = signees.map((signee, idx) => {
            return {
                signee, 
                uiComp: renderSigneeRow(signee, idx, handleChange(signees, setSignees, `[${idx}]`)),
            };
        });

        // table 
        const groups = [
            <span>Disse personer skal skrive under først</span>,
            <span>Dernæst skal revisoren skrive under</span>,
            <span>
                Dirigenten skriver under til sidst
                {
                    includeInternalReport &&
                    !isFlowLockedEsignatur(flow?.status) &&
                    ', dog ikke på den interne årsrapport'
                }
            </span>,
        ];

        return (
            groups
            .map((groupHeader, seqidx) => {
                // find all signees by sequence number
                const sequenceNum = seqidx + 1;
                return {
                    signees: m.filter(obj => obj.signee.sequenceNumber === sequenceNum),
                    header: groupHeader,
                };
            })
            .filter(group => group.signees.length > 0)
            .map((group, groupIdx) => {
                const groupNum = groupIdx + 1;
                const signeeTxStatuses = group.signees.map(({ signee }) => signee.txStatus);

                const groupIsDone = signeeTxStatuses.every(signeeHasSigned);
                const groupIsActive = signeeTxStatuses.some(txStatus => txStatus === SigneeStateStarted);

                return (
                    <SigneeGroup
                        number={groupNum}
                        header={group.header}
                        active={groupIsActive}
                        done={groupIsDone}
                        children={group.signees.map(signee => signee.uiComp)}
                    />
                );
            })
        );
    }

    const renderSigneeRow = (signee, idx, changeHandler) => {
        const editSigneeModal = (
            <Modal
                onClose={() => setOpenChangeEmailModal(-1)}
                onOpen={() => setOpenChangeEmailModal(signee.id)}
                open={openChangeEmailModal === signee.id}
            >
                <Modal.Header>Skift email</Modal.Header>
                <Modal.Content>
                <Modal.Description>
                    <Form>
                        {renderChangeEmail(signee.email)} 
                    </Form>
                </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                <Button color='black' onClick={() => setOpenChangeEmailModal(-1)}>
                    Annuller
                </Button>
                <Button
                    primary
                    disabled={newEmailInvalid || newEmail === signee.email}
                    onClick={(_) => handleChangeEmail(signee.id, signee.email, newEmail)}
                    content='Skift email' 
                    icon='pencil'
                />
                </Modal.Actions>
            </Modal>
        );

        return (
            <>
                {editSigneeModal}
                <Table.Row key={`${signee.title}-${idx}`}>
                    <Table.Cell collapsing>
                        <SigneeStatus status={signee.txStatus} />
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Group widths='equal' inline>
                            { renderName(signee.name, signee.title, changeHandler) }
                            { renderPhone(signee.signingMethod, signee.phone, changeHandler) }
                            { renderEmail(signee.email, changeHandler) }
                        </Form.Group>
                    </Table.Cell>
                    <Table.Cell collapsing>
                        { renderRadioButtons(signee.signingMethod, changeHandler) }
                    </Table.Cell>
                    { ( flow?.status === "Active" && (signee?.txStatus === "Active" || signee?.txStatus === "Pending") && !!flow?.flowId) ? <Table.Cell collapsing>{renderSigneePopupMenu(signee.id, signee?.txStatus, signee.email)}</Table.Cell> : <></> }
                </Table.Row>
            </>
        );
    }

    const renderName = (nameValue, title, changeHandler) => {
        return <SigneeField label={title || "Navn"}>
            <Input
                className={styles.disabledField}
                fluid required type="text" autoComplete="new-password"
                placeholder="Navn"
                disabled
                value={nameValue}                
                onChange={ (_, { value }) => changeHandler("name", value) }
                />
        </SigneeField>;
    }

    const renderEmail = (emailValue, changeHandler) => {
        return <SigneeField label={"Email"}>
            <Input
                fluid
                required
                type="email"
                autoComplete="new-password"
                placeholder="eks: mail@test.com"
                disabled={fieldsDisabled}
                value={emailValue}
                onChange={ (_, { value }) => changeHandler("email", value) }
                icon={
                    invalidEmails.has(emailValue) &&
                    <Icon name={'warning circle'} color='orange'/>
                }
            />
        </SigneeField>;
    }

    const renderChangeEmail = (oldEmail) => {
        return <SigneeField label={"Ny email"}>
            <Input
                fluid
                required
                type="email"
                autoComplete="new-password"
                placeholder="eks: mail@test.com"
                value={newEmail}
                onChange={ (_, { value }) => setNewEmail(value) }
                icon={
                    (newEmailInvalid || newEmail === oldEmail) && newEmail !== "" &&
                    <Icon name={'warning circle'} color='orange'/>
                }
            />
        </SigneeField>
    }

    const renderPhone = (signingMethod, phoneValue, changeHandler) => {
        if (signingMethod !== 2) {
            return;
        }
        return <SigneeField label="Telefon">
            <Input
                fluid type="tel" autoComplete="new-password"
                placeholder="eks: 22334455"
                disabled={fieldsDisabled}
                value={phoneValue}
                onChange={ (_, { value }) => changeHandler("phone", value) }
                labelPosition='left' >
                <Label >+45</Label> 
                <input />
            </Input>
        </SigneeField>;
    }

    const renderSignGroupButtons = (includeRevisor, changeHandler) => {
        const includeRevisorButton = signees.some((elem) => elem.sequenceNumber === 2)
        return <SigneeField label="Hvem skal se dokumentet?">
            <Form.Checkbox
                label='Samlet direktion' // Ændre til at det er et spørgsmål om revison OGSÅ skal skrive under
                value={true}
                checked={true}
                disabled={true}
            />
            { includeRevisorButton ? 
                <Form.Checkbox
                    label='Revisor'
                    value={includeRevisor}
                    checked={includeRevisor}
                    disabled={fieldsDisabled}
                    onChange={ (_, { value }) => changeHandler("includeRevisor", !value) }
                /> 
                : 
                <></>
            }
        </SigneeField>;
    }

    const renderSignTypeButtons = (signingType, changeHandler) => {
        return <SigneeField label="Skal dokumentet vises eller underskrives?">
            <Form.Radio
                label='Vises'
                value={false}
                checked={signingType === false}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("sign", ( signingType ? value : !value)) }
            />
            <Form.Radio
                label='Underskrives'
                value={true}
                checked={signingType === true}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("sign", ( signingType ? !value : value)) }
            />
        </SigneeField>;
    }

    const renderFileButtons = (idx) => {
        return <ColoredText link icon='trash' color="grey" onClick={() => handleFileDelete(idx)}/>
    }

    const renderDownloadButton = (signedFileid, fileId) => {
        if (flow?.status === "Completed") {
            if (!!signedFileid) {
                if (signedFileid?.length > 0) {
                    return <Button primary onClick={(e) => downloadClicked(signedFileid)}>Download</Button>
                } 
            } else if (!!fileId) {
                if (fileId?.length > 0) {
                    return <Button primary onClick={(e) => downloadClicked(fileId)}>Download</Button>
                } 
            }
        }
        
        return <></>
    }

    const renderRadioButtons = (signingMethod, changeHandler) => {
        return <SigneeField label="Underskrivningsmetode">
            <Form.Radio
                label='MitID'
                value={1}
                checked={signingMethod === 1}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("signingMethod", value) }
            />
            <Form.Radio
                label='Touch'
                value={2}
                checked={signingMethod === 2}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("signingMethod", value) }
            />
        </SigneeField>;
    }

    const renderSigneePopupMenu = (signeeId, signeeStatus, signeeEmail) => {
        return <Popup
            on='click'
            trigger={<Icon name="ellipsis horizontal" size='large' link />}
            content={renderSigneePopupList(signeeId, signeeStatus, signeeEmail)}
            position='top center'
            hoverable
        />;
    }

    const renderSigneePopupList = (signeeId, signeeStatus, signeeEmail) => { 
        return <List>
            { (signeeId !== null) ? <List.Item>{<ReminderButton signeeId={signeeId} signeeStatus={signeeStatus} handleSendReminder={handleSendReminder}/>}</List.Item> : <></>}
            { (signeeId !== null && signeeEmail !== null) ? <List.Item>{renderChangeEmailButton(signeeId)}</List.Item> : <></> }
        </List>
    }

    const renderChangeEmailButton = (signeeId) => {
        return <ColoredText
            content='Skift email'
            icon='pencil'
            iconPosition='left'
            underlined={false}
            link
            onClick={() => setOpenChangeEmailModal(signeeId)}
        />
    }
   
    // display warnings (max one, with this precedence);
    // - signee uniqueness
    // - email duplicates
    const warnings = useMemo(() => {
        if (loadingFlow) {
            return <Loader
                size='big'
                inline='centered'
                active
            />;
        }

        if (hasNonUniqueSignees) {
            return <Segment secondary>
                <Icon name="warning" color="red" className={styles.numberLabel} />
                Alle underskrivere skal være unikke
            </Segment>;
        }

        if (badEmails.size > 0) {
            const plu = badEmails.size !== 1;
            return <Segment color='red'>
                <Icon name='warning circle' color='red' />
                Følgende e-mail{plu && 's'} er ikke sik{plu ? 're' : 'ker'}:
                <List bulleted>
                    {[...badEmails].map(email => (
                        <List.Item>{email}</List.Item>
                    ))}
                </List>
                <div>
                    Vælg venligst {plu ? 'nogle andre' : 'en anden'} e-mailadresse{plu && 'r'}
                    {' '}
                    og prøv igen.
                </div>
            </Segment>
        }

        // no warnings
        return null;

    }, [hasNonUniqueSignees, badEmails, loadingFlow]);

    const renderButton = () => {
        const buttonIsDisabled = (
            disabled ||
            isInputInvalid ||
            hasNonUniqueSignees ||
            signees.length === 0 ||
            badEmails.size > 0
        );

        return <Segment secondary textAlign='right' className="buttonSegment">
            <Button
                primary
                onClick={onBtnCLick}
                disabled={buttonIsDisabled}
                content='Send til underskrift'
                icon='signup'
            />
        </Segment>;
    };

    const onBtnCLick = async () => {
        const signeesCopy = JSON.parse(JSON.stringify(signees)); // deep copy

        // removes phone number from signees if touch is not signing method
        omitPhoneIfMediaIsEmail(signeesCopy);

        // trims spaces on values from input fields
        sanitizeSignees(signeesCopy);

        const newAttachedDocuments = await sanitizeDocuments(attachedDocuments);

        const result = await onSubmit({
            signees: signeesCopy,
            attachedSections: [...attachedSections],
            attachedDocuments: [...newAttachedDocuments],
            attachedSecondaryDocumentIndices: [...attachedSecondaryDocumentIndices],
            notificationMedia: 1,
            includeInternalReport,
        });

        const executionErrors = result?.executionErrors || {};
        const signflowError = executionErrors[docGenFactID]?.internalError || false;

        if (signflowError) {
            toast.error('Underskriftsforløbet kunne ikke startes...');
            return;
        }

        // TODO: This can cause signers to display an old name, if a signer has been renamed
        if (esignaturSignFlowId !== "") { 
            const fetchedFlow = await fetchFlow(esignaturSignFlowId);
            setFlow(fetchedFlow);
        }

        toast.success('Underskriftsforløbet er nu igangsat');
    };

     const sanitizeDocuments = async attachedDocumentsArray => {
        const newAttachedDocuments = [];
        await Promise.all(attachedDocumentsArray.map(async attachedDocument =>  {
            if (!attachedDocument?.fileId) {
                try {
                    const id = await uploadFile(attachedDocument.file)
                    newAttachedDocuments.push({fileId: id, filename: attachedDocument.file.name, includeRevisor: attachedDocument.includeRevisor, sign: attachedDocument.sign})
                } catch (err) {
                    console.error("failed uploadFile: ", err);
                }
            }
        }))

        return newAttachedDocuments
    }

    const sanitizeSignees = signeesArray => {
        signeesArray.forEach(signee => {
            signee.email = signee.email?.trim();
            signee.name = signee.name?.trim();
        });
    };

    const omitPhoneIfMediaIsEmail = signeesArray => {
        try {
            
            for (let i=0; i<signeesArray.length; i++) {
                if (signeesArray[i].signingMethod !== 2) {
                    delete signeesArray[i].phone;
                }   
            }    
        } catch (err) {
            console.error("Omit phone if not touch failed:", err);
        }
    }

    const isInputInvalid = useMemo(() => {
        if (loadingFlow) {
            return <Loader
                size='big'
                inline='centered'
                active
            />;
        }

        try {
            // check emails (not empty, regex)
            const emailRegex = new RegExp(getEmailRegexp());
            const emailInvalid = email => !email || !emailRegex.test(email);
            const foundInvalidEmails = signees
                .map(signee => signee.email)
                .filter(emailInvalid)
                .length > 0;

            if (foundInvalidEmails) return true;

            return false;
        } catch (err) {
            console.error("Calc form validity failed:", err);
        }
        return false;
    }, [signees, loadingFlow]);

    if (!flow) {
        if (!loadingFlow) {
            return <Loader
                size='big'
                inline='centered'
                active
            />;
        }

        if (productStatus === userStatusesEnum.SIGNATURE_FLOW || productStatus === userStatusesEnum.ESIGNATUR_FLOW) {
            return <LegacyFlowStatus ongoing />;
        }
        if (productStatus === userStatusesEnum.DONE || productStatus === userStatusesEnum.COMPLETED) {
            return <LegacyFlowStatus done />;
        }
        console.error("no signflow")
        return null;
    }

    const shouldShowSignflowOptions = () => {
        const userIsAccountantOrAdmin = isAccountant(userData) || isAdmin(userData);
        if (!userIsAccountantOrAdmin) {
            return false;
        }

        // The dirigent should only sign the external report
        // so the sign flow options are not necessary.
        // Also, dirigent is not part of class A sign flows.
        const onlyDirigentShouldSign = flow?.signees?.length === 1 && !isVirksomhed(userData);
        if (onlyDirigentShouldSign) {
            return false;
        }

        if (isFlowLockedEsignatur(flow?.status)) {
            return false;
        }

        return true;
    };

    return (
        <FieldGroup
            title='Digital underskrift'
            progress={flow?.status === 'Completed' ? 100 : 0}
            unwrapped
            collapsible
            defaultOpen
        >
            {/* TODO kan error overhovedet eksistere? bør vi vise fejl f.eks. fra cvr fetch? vise hvorfor knappen er disabled? */}
            {
                error && <Segment>
                    <Icon name='warning circle' />Årsrapporten kan ikke underskrives
                    <br />
                    <strong>Årsag:</strong> {error}
                </Segment> 
            }

            <FlowHeader
                flowId={flow?.flowId}
                flowStatus={flow.status}
                reloadProductData={reloadProductData}
                setFlow={setFlow}
                esignaturSignFlowId={esignaturSignFlowId}
                attachedDocuments={flow?.attachedDocuments || []}
            />
            
            {shouldShowSignflowOptions() && (
                <Segment>
                    {renderPrintOptions()}
                    {renderTaxReturnOption()}
                    {renderAdditionalDocumentsPicker()}          
                </Segment>
            )}

            {renderSignees()}

            <Transition.Group animation='fade down' duration={400}>
                {warnings}
            </Transition.Group>

            {renderButton()}
        </FieldGroup>
    );
}

Esignatur.propTypes = {
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    pdfId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productStatus: PropTypes.string,
    signFlow: PropTypes.shape({
        flowStatus: PropTypes.string,
        signees: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string,
            sequenceNumber: PropTypes.number.isRequired,
            signingMethod: PropTypes.number.isRequired,
            txStatus: PropTypes.number,
        })),
    }),
    esignaturSignFlow: PropTypes.shape({
        flowStatus: PropTypes.string,
        signees: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string,
            sequenceNumber: PropTypes.number.isRequired,
            signingMethod: PropTypes.number.isRequired,
            txStatus: PropTypes.string,
            id: PropTypes.number,
        })),
    }),
    reloadProductData: PropTypes.func.isRequired,
};

const SigneeGroup = ({ number, active, done, header, children }) => {
    return <>
        <Segment secondary className={active ? styles.active : ""}>
            { !!number && number + ". " }
            { header }
            { active && !done && <Icon name='paper plane' className={styles.signeeGroupIcon} color='blue' /> }
            { done && <Icon color="green" name='check' className={styles.signeeGroupIcon} /> }
        </Segment>
        <Segment>
            <Table basic='very' unstackable compact='very'>
                <Table.Body>
                    { children }
                </Table.Body>
            </Table>
        </Segment>
    </>;
}

const SigneeField = ({label, children}) => {
    return <Form.Field>
        <label>{label}</label>
        {children}
    </Form.Field>;
}

export default compose(
    withUserData,
    withRouter,
)(Esignatur);
