import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    uploadTooltip: i18n({
        [daDK]: 'Vedhæft bilag',
        [enUS]: 'Upload file',
    }),
    file: i18n({
        [daDK]: 'bilag vedhæftet',
        [enUS]: 'file attached',
    }),
    files: i18n({
        [daDK]: 'bilag vedhæftet',
        [enUS]: 'files attached',
    }),
    loading: i18n({
        [daDK]: 'Henter data',
        [enUS]: 'Loading',
    }),
};