import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Modal, Popup } from 'semantic-ui-react';
import debounce from 'lodash.debounce';

const Question = ({ disabled, questionText, answer, onAnswer, onDelete }) => {
    const deleteIcon = onDelete && (
        <Icon
            disabled={disabled}
            onClick={onDelete}
            name='trash'
            color='red'
            link
        />
    );

    return (
        <Form.Field>
            <label>{questionText} {deleteIcon}</label>
            <textarea
                disabled={disabled}
                onChange={e => onAnswer(e.target.value)}
                defaultValue={answer}
            />
        </Form.Field>
    );
};

const QuestionsViewer = ({ disabled, account, provideQuestionAnswers, addCustomQuestionToGroup, deleteCustomQuestionFromGroup }) => {
    const [newQuestionText, setNewQuestionText] = useState('');
    const [newQuestionFormOpen, setNewQuestionFormOpen] = useState(false);
    const [updateQueue, setUpdateQueue] = useState([]);

    const { associatedQuestionsGroup } = account;

    const handleQuestionAnswerChange = (questionID, questionAnswerValue, isCustomQuestion) => {
        const updateQueueCopy = [...updateQueue];

        let updateToModify = updateQueueCopy.find(update => update.questionID === questionID);

        if (!updateToModify) {
            updateToModify = {
                questionID,
                isCustomQuestion,
                groupID: associatedQuestionsGroup.groupID,
            };
            updateQueueCopy.push(updateToModify);
        }

        updateToModify.questionAnswerValue = questionAnswerValue;

        setUpdateQueue(updateQueueCopy);
    };

    useEffect(() => {
        if (updateQueue.length === 0) return;

        const timeout = window.setTimeout(() => {
            provideQuestionAnswers(updateQueue);
            setUpdateQueue([]);
        }, 600);

        return () => window.clearTimeout(timeout);
    }, [updateQueue, provideQuestionAnswers]);

    const saveNewQuestionGroup = async () => {
        if (!newQuestionText) return;

        await addCustomQuestionToGroup({
            groupID: associatedQuestionsGroup.groupID,
            questionText: newQuestionText,
        });

        setNewQuestionText('');
        setNewQuestionFormOpen(false);
    };

    const systemDefinedQuestionsAndAnswers = associatedQuestionsGroup?.questionsAndAnswers || [];
    const usersCustomQuestionsAndAnswers = associatedQuestionsGroup?.state?.customGroupQuestions || [];

    const questionForm = systemDefinedQuestionsAndAnswers.length > 0 && (
        <Form>
            {systemDefinedQuestionsAndAnswers.map(({ id, questionText, answerValue }) => {
                return (
                    <Question
                        disabled={disabled}
                        key={id}
                        questionText={questionText}
                        answer={answerValue}
                        onAnswer={answer => handleQuestionAnswerChange(id, answer, false)}
                    />
                );
            })}

            {usersCustomQuestionsAndAnswers.map(({ questionID, questionText, answerValue }) => {
                return (
                    <Question
                        disabled={disabled}
                        key={questionID}
                        questionText={questionText}
                        onDelete={() => deleteCustomQuestionFromGroup(associatedQuestionsGroup.groupID, questionID)}
                        answer={answerValue}
                        onAnswer={answer => handleQuestionAnswerChange(questionID, answer, true)}
                    />
                );
            })}

            <Form.Field>
                <Popup
                    flowing
                    basic
                    position='top center'
                    on='click'
                    disabled={disabled}
                    open={newQuestionFormOpen}
                    onClose={() => setNewQuestionFormOpen(false)}
                    content={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                            <strong>Indtast titel på arbejdshandling:</strong>
                            <Input
                                placeholder='Titel...'
                                autoFocus
                                onChange={(_, { value }) => setNewQuestionText(value)}
                                onKeyDown={e => e.key === 'Enter' && saveNewQuestionGroup()}
                            />
                            <Button
                                fluid
                                primary
                                content='Gem'
                                disabled={!newQuestionText}
                                onClick={saveNewQuestionGroup}
                            />
                        </div>
                    }
                    trigger={
                        <Button
                            fluid
                            basic
                            disabled={disabled}
                            color={newQuestionFormOpen ? 'grey' : 'black'}
                            content='Tilføj ny arbejdshandling'
                            icon='plus'
                            onClick={() => setNewQuestionFormOpen(true)}
                        />
                    }
                />
            </Form.Field>
        </Form>
    );
    
    return (
        <Modal.Content>
            {questionForm || <i>Ingen spørgsmål at vise...</i>}
        </Modal.Content>
    );
};

export default QuestionsViewer;