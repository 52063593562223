import React, { useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import Form from 'design/atoms/Form';
import { getEntityLogins } from 'http/accounts';

const InputLoginSelectorWidget = ({ label, tooltip, youtubeVideo, onChange, value, supply }) => {
	const [entityLogins, setEntityLogins] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		getEntityLogins()
			.then(logins => setEntityLogins(logins))
			.catch(err => setError(err))
			.finally(() => setLoading(false));
	}, []);

	const handleNewLoginPicked = loginID => {
		onChange(supply, {
			string: loginID,
		});
	};

	const loginOptions = entityLogins.map(login => {
		return {
			key: login.id,
			value: login.id,
			text: login.email,
			description: login.role.title,
			icon: <Icon name={login.role.icon} color={login.role.color} />,
		};
	});
	
	let errorMessage;
	if (error) {
		errorMessage = (
			error.status === 403
			? 'Du har ikke rettighed til at besvare dette spørgsmål'
			: 'Der opstod en fejl'
		);
	} 

	return (
		<Form.Row>
			<Form.LabelField
				label={label}
				tooltip={tooltip}
				youtubeVideo={youtubeVideo}
			/>
			<Form.Field error={errorMessage} textAlign='right'>
				<Dropdown
					key={loading}
					selection
					search
					fluid
					error={!!errorMessage}
					loading={loading}
					placeholder='Vælg en person...'
					options={loginOptions}
					value={value?.string}
					onChange={(_, { value }) => handleNewLoginPicked(value)}
				/>
			</Form.Field>
		</Form.Row>
	);
};

export default InputLoginSelectorWidget;
