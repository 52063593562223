import { HTTPClient } from '../network/fetch';
import { BUSINESSCENTRAL_CALLBACK_URL } from 'config/config';

const client = new HTTPClient({ prefix: 'businesscentral/api' });

export function registerCredentials(tenant, application, secret) {
    console.log("registerCredentials")
    return client.postResource('register', {
        tenant,
        application,
        secret,
    });
}

export function registerCompany(businessID, businessName) {
    console.log("registerCompany")
    return client.postResource('register/company', {
        businessID,
        businessName,
    });
}

export function extractPrefillValues(token) {
    return client.getResource(`prefill/${token}`);
}

export async function getMSreauthLink() {
    try {

        const oauthRedirect = await client.getResource('getreauthlink');
        const tenant = oauthRedirect.Tenant;
        const application = oauthRedirect.Application;
        const callbackurl = BUSINESSCENTRAL_CALLBACK_URL;
        const chosenUserID = oauthRedirect.Uid;

        // ==== encode userID and redirect in a state object ===========================
        const stateObj = {
            userID: chosenUserID,
            returnUrl: window.location.href // Current page URL
        };
        const encodedState = encodeURIComponent(JSON.stringify(stateObj));
        // =============================================================================
        const BUSINESS_CENTRAL_LOGIN_PAGE = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${application}&response_type=code&redirect_uri=${callbackurl}&scope=https://api.businesscentral.dynamics.com/.default&state=${encodedState}`;

        return BUSINESS_CENTRAL_LOGIN_PAGE;
    }catch (error) {
        console.error("Error in getMSreauthLink:", error);
    }
    //return client.getResource('getreauthlink');
}