import React, { Component } from 'react'
import { Message, Form, Input, Button, Modal, Dropdown } from 'semantic-ui-react';
import { registerCredentials, registerCompany } from 'http/businesscentral';
import styles from './connectors.module.scss';
import { getChosenUser } from '../../token';
import { BUSINESSCENTRAL_CALLBACK_URL } from 'config/config';

import { unsetParam } from '../../QueryParams';

class BusinessCentralConnector extends Component {
    state = { 
        failed: false, 
        working: false, 
        companies: [], 
        selectedCompany: null, 
        selectedCompanyName: null,
        showModal: false  
    };

    componentDidMount() {
        // Check if redirected with companies in URL
        const params = new URLSearchParams(window.location.search);
        const encodedCompanies = params.get("companies");

        console.log("[Debug]encodedCompanies: ", encodedCompanies)

        if (encodedCompanies) {
            console.log("encoded companies found ");
            try {
                const decodedString = decodeURIComponent(encodedCompanies);
                console.log("decodedString: ", decodedString);
                const companiesJSON = atob(decodedString);
                console.log("companiesJSON: ", companiesJSON);
                const companies = JSON.parse(companiesJSON);
                console.log("[DEBUG] Parsed companies:", companies);
        
                if (companies.value && companies.value.length > 0) {
                    this.setState({ companies: companies.value, showModal: true });
                }
            } catch (error) {
                console.error("[ERROR] Failed to parse companies:", error);
            }
        }
    } 

    setCredentialValue = credProp => {
        return (_, { value }) => {
            this.setState({ [credProp]: value });
        };
    };

    displayErrorMessage = () => {
        const { failed } = this.state;
        if (failed) {
            return <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Kontroller oplysningerne</p>
            </Message>;
        }
    };

    registerERP = async () => {
        const { tenant, client, secret } = this.state;
        if (!tenant || !client || !secret) {
            console.log("Somethings wrong");
            return;
        }

        this.setState({ working: true });

        try {
            console.log("Calling registerCredentials");
            const results = await registerCredentials(tenant, client, secret);

            //const chosenUserID = this.props.userData.id;
            const chosenUserID = results.UserID;
            // Save current path in sessionStorage
            window.sessionStorage.afterEconomicIntegrationRedirectPath = window.location.pathname;
            //const callbackurl = "https://b291-80-209-42-36.ngrok-free.app/businesscentral/callback";

            //const callbackurl = BUSINESSCENTRAL_CALLBACK_URL;
            const callbackurl = results.RedirURL;
            // Construct the login URL dynamically with the current path as the state
            //const BUSINESS_CENTRAL_LOGIN_PAGE = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${client}&response_type=code&redirect_uri=${callbackurl}&scope=https://api.businesscentral.dynamics.com/.default&state=${encodeURIComponent(window.location.pathname)}`;

            // ==== encode userID and redirect in a state object ===========================
            const stateObj = {
                userID: chosenUserID,
                returnUrl: window.location.href // Current page URL
            };
            const encodedState = encodeURIComponent(JSON.stringify(stateObj));
            // =============================================================================

            const BUSINESS_CENTRAL_LOGIN_PAGE = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${client}&response_type=code&redirect_uri=${callbackurl}&scope=https://api.businesscentral.dynamics.com/.default&state=${encodedState}`;
            window.location.href = BUSINESS_CENTRAL_LOGIN_PAGE;
            

            this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false })
        }
    };

    updateERPbusiness = async () => {
        const { selectedCompany, companies  } = this.state;
        const company = companies.find(c => c.id === selectedCompany);
        const companyName = company ? company?.name : null;

        if (!selectedCompany || !companyName) {
            console.log("=== Somethings wrong ====");
            console.log("companyID", selectedCompany);
            console.log("companyName", companyName);
            return;
        }
        this.setState({ working: true });
        try {
            const results = await registerCompany(selectedCompany, companyName);
            return results;
            
            //this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false })
        }
    };

    handleCompanySelect = (_, { value }) => {
        this.setState({ selectedCompany: value });
    };

    confirmCompanySelection = async () => {
        const { selectedCompany } = this.state;
        if (!selectedCompany) return;

        try {
            const updateresults = await this.updateERPbusiness();
            console.log("updateresults: ", updateresults);
            this.setState({ showModal: false });
            unsetParam("companies");
            this.props.onRegister();
        } catch (error) {
            alert("An error occurred while selecting the company.");
        }
    };

    renderCredsForm = () => {
        return <Form>
            <Form.Field>
                <label>Directory (tenant)</label>
                <Input
                    placeholder='Indtast tenant ID'
                    onChange={this.setCredentialValue('tenant')}
                    disabled={this.state.working}
                />
            </Form.Field>

            <Form.Field>
                <label>Application (client)</label>
                <Input
                    placeholder='Indtast client ID'
                    onChange={this.setCredentialValue('client')}
                    disabled={this.state.working}
                />
            </Form.Field>

            <Form.Field>
                <label>API Secret (value)</label>
                <Input
                    placeholder='Indtast Microsoft Connector Secret'
                    onChange={this.setCredentialValue('secret')}
                    disabled={this.state.working}
                />
            </Form.Field>
        </Form>
    };

    renderCompanySelectionModal = () => {
        const { companies, showModal, selectedCompany } = this.state;

        const companyOptions = companies.map(company => ({
            key: company.id,
            text: company.name,
            value: company.id
        }));

        return (
            <Modal open={showModal} size="small">
                <Modal.Header>Vælg en virksomhed</Modal.Header>
                <Modal.Content>
                    <Dropdown
                        placeholder="Vælg en virksomhed"
                        fluid
                        selection
                        options={companyOptions}
                        onChange={this.handleCompanySelect}
                        value={selectedCompany}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.confirmCompanySelection}
                        disabled={!selectedCompany}
                        primary
                    >
                        Bekræft valg
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    render = () => {
        return (
            <div>
                {this.renderCredsForm()}
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.registerERP}
                        disabled={this.state.working}
                        loading={this.state.working}
                        content='Forbind'
                        primary
                    />
                </div>
                {this.displayErrorMessage()}
                {this.renderCompanySelectionModal()}
            </div>
        );
    };
}

export default BusinessCentralConnector;