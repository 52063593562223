import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { triggerDownload } from 'http/file-storage';
import ColoredText from 'design/atoms/ColoredText';

const DocgenDownloadLink = (props) => {
    const [working, setWorking] = useState(false);
    const [justGenerated, setJustGenerated] = useState(false);

    const handleDownload = async () => {
        setWorking(true);

        try {
            await props.regenerateDocument();

            setJustGenerated(true);
        } catch (e) {
            toast.error('Kunne ikke generere PDF...');
            setWorking(false);
        }
    };

    useEffect(() => {
        if (!justGenerated) return;
        if (!props.value) return;

        triggerDownload(props.value.pdf, 'attachment')
            .catch(() => toast.error('Kunne ikke downloade PDF...'))
            .finally(() => setWorking(false));

        setJustGenerated(false);
    }, [justGenerated, props.value])

    return (
        <ColoredText
            link
            loading={working}
            disabled={working}
            underlined={false}
            icon='download'
            iconPosition='left'
            content={props.label}
            onClick={handleDownload}
        />
    );
};

export default DocgenDownloadLink;