const customXbrlTags = {
    balance: [],
    drift: [
        {
            tag: "DigitalRevisorSalesExpenses",
            description: "Salgsomkostninger",
        },
        {
            tag: "DigitalRevisorCarExpenses",
            description: "Bilomkostninger",
        },
        {
            tag: "DigitalRevisorDirectExpenses",
            description: "Direkte omkostninger",
        },
    ],
};

const xbrl = {
    balance: [
        ...customXbrlTags.balance,
        {
            tag: "PropertyHeldForSaleInTheOrdinaryCourseOfBusiness",
            description: "Ejendomme som besiddes med henblik på videresalg i det normale forretningsforløb (Handelsejendomme)",
        },
        {
            tag: "LongtermReceivablesFromJointVentures",
            description: "Langfristede tilgodehavender hos joint ventures",
        },
        {
            tag: "NoncurrentContractLiabilities",
            description: "Langfristede kontraktlige forpligtelser",
        },
        {
            tag: "CurrentContractLiabilities",
            description: "Kortfristede kontraktlige forpligtelser",
        },
        {
            tag: "LongtermPayablesToParticipatingInterests",
            description: "Gæld til kapitalinteresser (langfristede)",
        },
        {
            tag: "ShorttermPayablesToParticipatingInterest",
            description: "Kortfristede gældsforpligtelser til kapitalinteresser",
        },
        {
            tag: "LongtermParticipatingInterests",
            description: "Kapitalinteresser (langfristede)",
        },
        {
            tag: "ShorttermReceivablesFromJointVentures",
            description: "Kortfristede tilgodehavender hos joint ventures",
        },
        {
            tag: "ShorttermReceivablesFromParticipatingInterests",
            description: "Kortfristede tilgodehavender hos kapitalinteresser",
        },
        {
            tag: "LongtermReceivablesFromParticipatingInterests",
            description: "Langfristede tilgodehavender hos kapitalinteresser",
        },
        {
            tag: "NoncurrentContractAssets",
            description: "Langfristede kontraktlige aktiver",
        },
        {
            tag: "GrossResult",
            description: "Bruttoresultat",
        },
        {
            tag: "EmployeeBenefitsExpense",
            description: "Personaleomkostninger",
        },
        {
            tag: "ProfitLossFromOrdinaryOperatingActivitiesBeforeGainsLossesFromFairValueAdjustments",
            description: "Resultat af ordinær drift før dagsværdireguleringer",
        },
        {
            tag: "ProfitLossFromOrdinaryOperatingActivities",
            description: "Resultat af ordinær drift",
        },
        {
            tag: "ProfitLossFromOrdinaryActivitiesBeforeTax",
            description: "Ordinært resultat før skat",
        },
        {
            tag: "ProfitLossFromOrdinaryActivitiesAfterTax",
            description: "Ordinært resultat efter skat",
        },
        {
            tag: "ProfitLoss",
            description: "Årets resultat",
        },
        {
            tag: "NoncurrentAssets",
            description: "Anlægsaktiver",
        },
        {
            tag: "IntangibleAssets",
            description: "Immaterielle anlægsaktiver",
        },
        {
            tag: "DevelopmentProjectsInProgress",
            description: "Udviklingsprojekter under udførelse",
        },
        {
            tag: "CompletedDevelopmentProjects",
            description: "Færdiggjorte udviklingsprojekter",
        },
        {
            tag: "OtherSimilarRightsOriginatingFromDevelopmentProjects",
            description:
                "Lignende rettigheder, der stammer fra udviklingsprojekter",
        },
        {
            tag: "AcquiredIntangibleAssets",
            description: "Erhvervede immaterielle anlægsaktiver",
        },
        {
            tag: "Goodwill",
            description: "Goodwill",
        },
        {
            tag: "DevelopmentProjectsInProgressAndPrepaymentsForIntangibleAssets",
            description:
                "Udviklingsprojekter under udførelse og forudbetalinger for immaterielle anlægsaktiver",
        },
        {
            tag: "PropertyPlantAndEquipment",
            description: "Materielle anlægsaktiver",
        },
        {
            tag: "LandAndBuildings",
            description: "Grunde og bygninger",
        },
        {
            tag: "InvestmentProperty",
            description: "Investeringsejendomme",
        },
        {
            tag: "OtherInvestmentAssets",
            description: "Andre investeringsaktiver",
        },
        {
            tag: "PlantAndMachinery",
            description: "Produktionsanlæg og maskiner",
        },
        {
            tag: "FixturesFittingsToolsAndEquipment",
            description: "Andre anlæg, driftsmateriel og inventar",
        },
        {
            tag: "BiologicalAssets",
            description: "Biologiske aktiver",
        },
        {
            tag: "LeaseholdImprovements",
            description: "Indretning af lejede lokaler",
        },
        {
            tag: "Ships",
            description: "Skibe",
        },
        {
            tag: "Planes",
            description: "Fly",
        },
        {
            tag: "PropertyPlantAndEquipmentInProgress",
            description: "Materielle aktiver under udførelse",
        },
        {
            tag: "PrepaymentsForPropertyPlantAndEquipment",
            description: "Forudbetalinger for materielle anlægsaktiver",
        },
        {
            tag: "LongtermInvestmentsAndReceivables",
            description: "Finansielle anlægsaktiver",
        },
        {
            tag: "LongtermInvestmentsInGroupEnterprises",
            description:
                "Kapitalandele i tilknyttede virksomheder (langfristede)",
        },
        {
            tag: "LongtermReceivablesFromGroupEnterprises",
            description:
                "Langfristede tilgodehavender hos tilknyttede virksomheder",
        },
        {
            tag: "LongtermInvestmentsInAssociates",
            description: "Kapitalandele i associerede virksomheder",
        },
        {
            tag: "LongtermReceivablesFromAssociates",
            description:
                "Langfristede tilgodehavender hos associerede virksomheder",
        },
        {
            tag: "LongtermInvestmentsInJointVentures",
            description: "Kapitalandele i joint ventures (langfristet)",
        },
        {
            tag: "OtherLongtermInvestments",
            description: "Andre værdipapirer og kapitalandele [langfristet]",
        },
        {
            tag: "OtherLongtermReceivables",
            description: "Andre (langfristede) tilgodehavender",
        },
        {
            tag: "LongtermReceivablesFromOwnersAndManagement",
            description:
                "Langfristede tilgodehavender hos virksomhedsdeltagere og ledelse",
        },
        {
            tag: "NoncurrentDeferredTaxAssets",
            description: "Udskudte skatteaktiver (anlægsaktiver)",
        },
        {
            tag: "DepositsLongtermInvestmentsAndReceivables",
            description: "Deposita, finansielle anlægsaktiver",
        },
        {
            tag: "CostExceedsIncomeForTheFinancialYearLongtermReceivables",
            description: "Underdækning, langfristede tilgodehavender",
        },
        {
            tag: "ContributedCapitalInArrearsLongTerm",
            description:
                "Krav på indbetaling af registreret kapital mv.(langfristet)",
        },
        {
            tag: "CurrentAssets",
            description: "Kortfristede aktiver",
        },
        {
            tag: "Inventories",
            description: "Varebeholdninger",
        },
        {
            tag: "RawMaterialsAndConsumables",
            description: "Råvarer og hjælpematerialer",
        },
        {
            tag: "WorkInProgress",
            description: "Varer under fremstilling",
        },
        {
            tag: "ManufacturedGoodsAndGoodsForResale",
            description: "Fremstillede varer og handelsvarer",
        },
        {
            tag: "PrepaymentsForGoods",
            description: "Forudbetaling for varer",
        },
        {
            tag: "Livestock",
            description: "Stambesætning",
        },
        {
            tag: "AssetsHeldForSaleInventories",
            description: "Aktiver bestemt for salg, varebeholdning",
        },
        {
            tag: "ShorttermReceivables",
            description: "Tilgodehavender",
        },
        {
            tag: "ShorttermTradeReceivables",
            description: "Tilgodehavende fra salg og tjenesteydelser",
        },
        {
            tag: "ContractWorkInProgress",
            description: "Igangværende arbejder for fremmed regning",
        },
        {
            tag: "ShorttermReceivablesFromGroupEnterprises",
            description:
                "Kortfristede tilgodehavende hos tilknyttede virksomheder",
        },
        {
            tag: "ShorttermReceivablesFromAssociates",
            description:
                "Kortfristede tilgodehavender hos associerede virksomheder",
        },
        {
            tag: "ShorttermReceivablesDividendsFromGroupEnterprises",
            description:
                "Kortfristede tilgodehavender udbytte hos tilknyttet virksomhed",
        },
        {
            tag: "ShorttermReceivablesDividendsFromAssociates",
            description:
                "Kortfristede tilgodehavender udbytte hos associerede virksomhed",
        },
        {
            tag: "CurrentDeferredTaxAssets",
            description: "Udskudte skatteaktiver",
        },
        {
            tag: "ShorttermTaxReceivables",
            description: "Kortfristet tilgodehavende skat",
        },
        {
            tag: "ShorttermTaxReceivablesFromGroupEnterprises",
            description:
                "Kortfristet tilgodehavende skat hos tilknyttede virksomheder",
        },
        {
            tag: "OtherShorttermReceivables",
            description: "Andre tilgodehavende (kortfristede)",
        },
        {
            tag: "ContributedCapitalInArrears",
            description:
                "Krav på indbetaling af registreret kapital mv. (kortfristet)",
        },
        {
            tag: "ShorttermReceivablesFromOwnersAndManagement",
            description:
                "Kortfristede tilgodehavender hos virksomhedsdeltagere og ledelse",
        },
        {
            tag: "DeferredIncomeAssets",
            description: "Periodeafgrænsningsposter, aktiver",
        },
        {
            tag: "CostExceedsIncomeForTheFinancialYearShorttermReceivables",
            description: "Underdækning, kortfristede tilgodehavender",
        },
        {
            tag: "TimingDifferencesShorttermReceivablesEspeciallyUtilities",
            description:
                "Tidsmæssige forskelle, tilgodehavender (særligt for forsyningsvirksomheder)",
        },
        {
            tag: "ShorttermInvestments",
            description: "Værdipapirer og kapitalandele",
        },
        {
            tag: "ShorttermInvestmentsInGroupEnterprises",
            description: "Kapitalandele i tilknyttede virksomheder",
        },
        {
            tag: "ShorttermInvestmentsInAssociates",
            description: "Kapitalandele i associerede virksomheder",
        },
        {
            tag: "InvestmentsInJointVentures",
            description: "Kapitalandele i joint ventures",
        },
        {
            tag: "OtherShorttermInvestments",
            description: "Andre værdipapirer og kapitalandele [kortfristet]",
        },
        {
            tag: "CashAndCashEquivalents",
            description: "Likvide beholdninger",
        },
        {
            tag: "AssetsMeantForSale",
            description: "Aktiver bestemt for salg",
        },
        {
            tag: "Assets",
            description: "Aktiver",
        },
        {
            tag: "Equity",
            description: "Egenkapital",
        },
        {
            tag: "ContributedCapital",
            description: "Registreret kapital",
        },
        {
            tag: "PaidContributedCapital",
            description: "Indbetalt registreret kapital mv.",
        },
        {
            tag: "SharePremium",
            description: "Overkurs ved emission",
        },
        {
            tag: "RevaluationReserve",
            description: "Reserve for opskrivninger",
        },
        {
            tag: "ReserveForNetRevaluationAccordingToEquityMethod",
            description:
                "Reserve for nettoopskrivning efter indre værdis metode",
        },
        {
            tag: "ReserveForLoansAndCollaterals",
            description: "Reserve for udlån og sikkerhedsstillelse",
        },
        {
            tag: "ReserveForUnpaidContributedCapital",
            description: "Reserve for ikke indbetalt registreret kapital mv.",
        },
        {
            tag: "ReserveForEntrepreneurialCompany",
            description: "Reserve for iværksætterselskab",
        },
        {
            tag: "ReserveForDevelopmentExpenditure",
            description: "Reserve for udviklingsomkostninger",
        },
        {
            tag: "ReserveForNetRevaluationOfInvestmentAssets",
            description: "Reserve for nettoopskrivning af investeringsaktiver",
        },
        {
            tag: "OtherStatutoryReserves",
            description: "Øvrige lovpligtige reserver",
        },
        {
            tag: "ReserveAccordingToArticlesOfAssociation",
            description: "Vedtægtsmæssige reserver",
        },
        {
            tag: "ReserveForBiologicalAssets",
            description: "Reserve for biologiske aktiver",
        },
        {
            tag: "RestOfOtherReserves",
            description: "Øvrige reserver",
        },
        {
            tag: "RetainedEarnings",
            description: "Overført resultat",
        },
        {
            tag: "Distributions",
            description: "Henlagt til uddelinger",
        },
        {
            tag: "ProposedDividendRecognisedInEquity",
            description: "Foreslået udbytte indregnet under egenkapitalen",
        },
        {
            tag: "ProposedExtraordinaryDividendRecognisedInEquity",
            description: "Ekstraordinært udbytte indregnet under egenkapitalen",
        },
        {
            tag: "NotPaidContributedCapital",
            description: "Ikke indbetalt registreret kapital mv.",
        },
        {
            tag: "HedgeFund",
            description: "Sikringsfond",
        },
        {
            tag: "ReserveFund",
            description: "ReserveFund",
        },
        {
            tag: "TransferredToFromReservesAvailable",
            description: "Disponible reserver",
        },
        {
            tag: "LiquidationAccount",
            description: "Likvidationskonto",
        },
        {
            tag: "ActuarialProfitLossWhichIsAPartOfTheCalculationOfContingentPensions",
            description:
                "Aktuarmæssige gevinster (tab), som indgår i opgørelsen af pensionsforpligtelsen",
        },
        {
            tag: "MinorityInterests",
            description: "Minoritetsinteresser",
        },
        {
            tag: "Provisions",
            description: "Hensatte forpligtelser",
        },
        {
            tag: "ProvisionsForPensionsAndSimilarLiabilities",
            description: "Hensættelser til pensioner og lignende forpligtelser",
        },
        {
            tag: "ProvisionsForDeferredTax",
            description: "Hensættelser til udskudt skat",
        },
        {
            tag: "OtherProvisions",
            description: "Andre hensatte forpligtelser",
        },
        {
            tag: "ProvisionsForInvestmentsInGroupEnterprises",
            description:
                "Hensættelser vedrørende kapitalandele i tilknyttede virksomheder",
        },
        {
            tag: "ProvisionsForInvestmentsInGroupAssociates",
            description:
                "Hensættelser vedrørende kapitalandele i associerede virksomheder",
        },
        {
            tag: "ProvisionsForInvestmentsInJointVentures",
            description:
                "Hensættelser vedrørende kapitalandele i joint ventures",
        },
        {
            tag: "ProvisionsForIncomeExceedCostForTheFinancialYear",
            description: "Hensættelser til overdækning",
        },
        {
            tag: "TimingDifferencesProvisionsEspeciallyUtilities",
            description:
                "Tidsmæssige forskelle, hensatte forpligtelser (særligt for forsyningsvirksomheder)",
        },
        {
            tag: "LongtermLiabilitiesOtherThanProvisions",
            description: "Langfristede forpligtelser",
        },
        {
            tag: "LongtermMortgageDebt",
            description: "Langfristet Gæld til realkreditinstitutter",
        },
        {
            tag: "LongtermDebtToBanks",
            description: "Langfristet Gæld til banker",
        },
        {
            tag: "OtherLongtermDebtRaisedByIssuanceOfBonds",
            description:
                "Anden langfristet gæld, der er optaget ved udstedelse af obligationer",
        },
        {
            tag: "LongtermDebtToOtherCreditInstitutions",
            description: "Langfristet gæld til kreditinstitutter i øvrigt",
        },
        {
            tag: "ConvertibleProfitYieldingOrDividendYieldingLongtermDebtInstruments",
            description:
                "Konvertible og udbyttegivende langfristede gældsbreve",
        },
        {
            tag: "LongtermPrepaymentsReceivedFromCustomers",
            description: "Modtagne forudbetalinger fra kunder (langfristede)",
        },
        {
            tag: "LongtermTradePayables",
            description:
                "Leverandører af varer og tjenesteydelser (langfristede)",
        },
        {
            tag: "LongtermBillsOfExchangePayable",
            description: "Langfristet vekselgæld",
        },
        {
            tag: "LongtermPayablesToGroupEnterprises",
            description: "Gæld til tilknyttede virksomheder (langfristede)",
        },
        {
            tag: "LongtermPayablesToAssociates",
            description: "Gæld til associerede virksomheder (langfristede)",
        },
        {
            tag: "LongtermPayablesToJointVentures",
            description: "Gæld til joint ventures (langfristet)",
        },
        {
            tag: "LongtermTaxPayables",
            description: "Skyldig skat (langfristet)",
        },
        {
            tag: "LongtermTaxPayablesToGroupEnterprises",
            description:
                "Selskabsskat (skyldig) til tilknyttede virksomheder (langfristet)",
        },
        {
            tag: "OtherPayablesIncludingTaxPayablesLiabilitiesOtherThanProvisionsLongterm",
            description: "Anden gæld, herunder skyldige skatter mv. (langfristet)",
        },
        {
            tag: "LongtermDeferredIncome",
            description: "Periodeafgrænsningsposter (langfristede)",
        },
        {
            tag: "LongtermNegativeGoodwill",
            description: "Negativ goodwill (langfristet)",
        },
        {
            tag: "LongtermLeaseCommitments",
            description: "Leasingforpligtelser (langfristede)",
        },
        {
            tag: "LongtermEquityLoan",
            description: "Langfristet ansvarlig lånekapital",
        },
        {
            tag: "LongtermPayablesToShareholdersAndManagement",
            description: "Langfristet gæld til selskabsdeltagere og ledelse",
        },
        {
            tag: "LongtermPrepaymentsOfWorkInProgress",
            description:
                "Langfristede modtagne forudbetalinger vedrørende igangværende arbejder",
        },
        {
            tag: "LongtermContractWorkInProgressLiabilities",
            description:
                "Langfristede igangværende arbejder for fremmed regning, forpligtelser",
        },
        {
            tag: "DepositsLongtermLiabilitiesOtherThanProvisions",
            description: "Deposita, langfristede gældsforpligtelser",
        },
        {
            tag: "IncomeExceedCostForTheFinancialYearLongterm",
            description: "Overdækning (langfristet)",
        },
        {
            tag: "ShorttermLiabilitiesOtherThanProvisions",
            description: "Kortfristede forpligtelser",
        },
        {
            tag: "ShorttermMortgageDebt",
            description: "Kortfristet Gæld til realkreditinstitutter",
        },
        {
            tag: "ShorttermDebtToBanks",
            description: "Kortfristet Gæld til banker",
        },
        {
            tag: "OtherShorttermDebtRaisedByIssuanceOfBonds",
            description:
                "Anden kortfristet gæld, der er optaget ved udstedelse af obligationer",
        },
        {
            tag: "ShorttermDebtToOtherCreditInstitutions",
            description: "Kortfristet gæld til kreditinstitutter i øvrigt",
        },
        {
            tag: "ConvertibleProfitYieldingOrDividendYieldingShorttermDebtInstruments",
            description: "Konvertible og udbyttegivende kortfristede gældsbreve",
        },
        {
            tag: "ShorttermPrepaymentsReceivedFromCustomers",
            description: "Kortfristede forudbetalinger fra kunder",
        },
        {
            tag: "ShorttermTradePayables",
            description:
                "Leverandører af varer og tjenesteydelser (kortfristede)",
        },
        {
            tag: "ShorttermBillsOfExchangePayable",
            description: "Kortfristet vekselgæld",
        },
        {
            tag: "ShorttermPayablesToGroupEnterprises",
            description:
                "Kortfristede gældsforpligtelser til tilknyttede virksomheder",
        },
        {
            tag: "ShorttermPayablesToAssociates",
            description:
                "Kortfristede gældsforpligtelser til associerede virksomheder",
        },
        {
            tag: "ShorttermPayablesToJointVentures",
            description: "Kortfristede gældsforpligtelser til joint ventures",
        },
        {
            tag: "ShorttermTaxPayables",
            description: "Kortfristet skyldig skat",
        },
        {
            tag: "ShorttermTaxPayablesToGroupEnterprises",
            description:
                "Kortfristet selskabsskat (skyldig) til tilknyttede virksomheder",
        },
        {
            tag: "OtherPayablesIncludingTaxPayablesLiabilitiesOtherThanProvisionsShortterm",
            description: "Anden gæld, herunder skyldige skatter mv. (kortfristet)",
        },
        {
            tag: "ShorttermDeferredIncome",
            description: "Kortfristet del af periodeafgrænsningsposter",
        },
        {
            tag: "ShorttermNegativeGoodwill",
            description: "Kortfristet del af negativ goodwill",
        },
        {
            tag: "ShorttermLeaseCommitments",
            description: "Leasingforpligtelser (kortfristede)",
        },
        {
            tag: "ProposedDividend",
            description: "Forslag til udbytte for regnskabsåret",
        },
        {
            tag: "ShorttermPartOfLongtermLiabilitiesOtherThanProvisions",
            description: "Kortfristet del af langfristet gæld",
        },
        {
            tag: "ShorttermEquityLoan",
            description: "Kortfristet ansvarlig lånekapital",
        },
        {
            tag: "ShorttermPayablesToShareholdersAndManagement",
            description: "Kortfristet gæld til selskabsdeltagere og ledelse",
        },
        {
            tag: "ShorttermPrepaymentsOfWorkInProgress",
            description:
                "Kortfristede modtagne forudbetalinger vedrørende igangværende arbejder",
        },
        {
            tag: "ShorttermContractWorkInProgressLiabilities",
            description:
                "Kortfristede igangværende arbejder for fremmed regning, forpligtelser",
        },
        {
            tag: "DepositsShorttermLiabilitiesOtherThanProvisions",
            description: "Deposita, kortfristede gældsforpligtelser",
        },
        {
            tag: "IncomeExceedCostForTheFinancialYearShortterm",
            description: "Overdækning (kortfristet)",
        },
        {
            tag: "LiabilitiesRelatedToAssetsMeantForSale",
            description: "Forpligtelser forbundet med aktiver bestemt for salg",
        },
        {
            tag: "LiabilitiesAndEquity",
            description: "Passiver",
        },
        {
            tag: "EgenkapitalPrimo",
            description: "Egenkapital, primo",
        },
        {
            tag: "AaretsIndskud",
            description: "Årets indskud",
        },
        {
            tag: "KontantHaevet",
            description: "Kontant hævet",
        },
        {
            tag: "PrivateAndele",
            description: "Private andele",
        },
        {
            tag: "PrivatBalance",
            description: "Privat Balance",
        },
        {
            tag: "DebtToCreditInstitutions",
            description: "Gæld til kreditinstituter",
        },
        {
            tag: "DebtToBanks",
            description: "Gæld til banker",
        },
        {
            tag: "VatAndDutiesPayables",
            description: "Skyldig moms og afgifter",
        },
        {
            tag: "VatAndDutiesReceivables",
            description: "Tilgodehavender moms og afgifter",
        },
        {
            tag: "OtherLongtermPayables",
            description: "Anden langfristet gæld",
        },
        {
            tag: "Buildings",
            description: "Bygninger",
        },
        {
            tag: "Land",
            description: "Grunde",
        },
        {
            tag: "OtherShorttermInvestments",
            description: "Andre værdipapirer og kapitalandele",
        },
        {
            tag: "BiologicalAssets",
            description: "Biologiske aktiver",
        },
        {
            tag: "OvrigeKapitalPoster",
            description: "Øvrige kapitalposter",
        },
        {
            tag: 'AcquiredIntangibleAssets',
            description: 'Erhvervede immaterielle anlægsaktiver',
        },
    ],
    drift: [
        ...customXbrlTags.drift,
        {
            tag: "Revenue",
            description: "Nettoomsætning",
        },
        {
            tag: "InterestExpenseToParticipatingInterests",
            description: "Renteomkostninger til kapitalinteresser",
        },
        {
            tag: "InterestIncomeFromParticipatingInterests",
            description: "Renteindtægter fra kapitalinteresser",
        },
        {
            tag: "CostOfSales",
            description: "Vareforbrug",
        },
        {
            tag: "ChangeInInventoriesOfFinishedGoodsWorkInProgressAndGoodsForResale",
            description:
                "Ændring i lagre af færdigvarer og varer under fremstilling",
        },
        {
            tag: "WorkPerformedByEntityAndCapitalised",
            description:
                "Arbejde udført for egen regning og opført under aktiver",
        },
        {
            tag: "DebtReliefOrSimilarFinanceIncome",
            description: "Gældseftergivelse og lignende, finansielle indtægter",
        },
        {
            tag: "OtherOperatingIncome",
            description: "Andre driftsindtægter",
        },
        {
            tag: "OtherInterestIncome",
            description: "Renteindtægter i øvrigt",
        },
        {
            tag: "OtherInterestExpenses",
            description: "Renteomkostninger i øvrigt",
        },
        {
            tag: "InterestIncomeFromGroupEnterprises",
            description: "Renteindtægter fra tilknyttede virksomheder",
        },
        {
            tag: "InterestIncomeFromAssociates",
            description: "Renteindtægter fra associerede virksomheder",
        },
        {
            tag: "IncomeFromInvestmentsInParticipatingInterests",
            description: "Indtægter af kapitalinteresser",
        },
        {
            tag: "OtherStatutoryReservesMember",
            description:
                "Overført til reserve for udviklingsomkostninger (Resultatdisponering)",
        },
        {
            tag: "InterestExpenseAssignedToAssociates",
            description: "Renteomkostninger til associerede virksomheder",
        },
        {
            tag: "InterestExpenseAssignedToGroupEnterprises",
            description: "Renteomkostninger til tilknyttede virksomheder",
        },
        {
            tag: "PropertyCost",
            description: "Ejendomsomkostninger",
        },
        {
            tag: "AdministrativeExpenses",
            description: "Administrationsomkostninger",
        },
        {
            tag: "RawMaterialsAndConsumablesUsed",
            description: "Omkostninger til råvarer og hjælpematerialer",
        },
        {
            tag: "OtherExternalExpenses",
            description: "Andre eksterne omkostninger",
        },
        {
            tag: "WagesAndSalaries",
            description: "Lønninger",
        },
        {
            tag: "PostemploymentBenefitExpense",
            description: "Pensioner",
        },
        {
            tag: "SocialSecurityContributions",
            description: "Andre omkostninger til social sikring",
        },
        {
            tag: "EmployeeExpensesTransferredToAssets",
            description: "Personaleomkostninger overført til aktiver",
        },
        {
            tag: "OtherEmployeeExpense",
            description: "Andre personaleomkostninger",
        },
        {
            tag: "DepreciationAmortisationExpenseAndImpairmentLossesOfPropertyPlantAndEquipmentAndIntangibleAssetsRecognisedInProfitOrLoss",
            description:
                "Af- og nedskrivninger af materielle og immaterielle anlægsaktiver",
        },
        {
            tag: "WritedownsOfCurrentAssetsOtherThanCurrentFinancialAssets",
            description:
                "Nedskrivning af omsætningsaktiver, bortset fra finansielle omsætningsaktiver",
        },
        {
            tag: "WritedownsOfCurrentAssetsThatExceedNormalWritedowns",
            description:
                "Nedskrivning af omsætningsaktiver, som overstiger normale nedskrivninger",
        },
        {
            tag: "OtherOperatingExpenses",
            description: "Andre driftsomkostninger",
        },
        {
            tag: "IncomeFromNegativeGoodwill",
            description: "Indtægter af negativ goodwill",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfInvestmentProperty",
            description: "Dagsværdiregulering af investeringsejendomme",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfOtherInvestmentAssets",
            description: "Dagsværdiregulering af andre investeringsaktiver",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfInvestmentAssets",
            description: "Dagsværdiregulering af investeringsaktiver",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfBiologicalAssets",
            description: "Dagsværdiregulering af biologiske aktiver",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfDebtLiabilitiesConcerningInvestmentProperty",
            description:
                "Dagsværdiregulering af gæld vedrørende investeringsejendomme",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfDebtLiabilitiesConcerningOtherInvestmentAssets",
            description:
                "Dagsværdiregulering af gæld vedrørende andre investeringsaktiver",
        },
        {
            tag: "ResearchExpenditure",
            description: "Forskningsomkostninger",
        },
        {
            tag: "DevelopmentExpenditure",
            description: "Udviklingsomkostninger",
        },
        {
            tag: "IncomeFromInvestmentsInGroupEnterprises",
            description:
                "Indtægter af kapitalandele i tilknyttede virksomheder",
        },
        {
            tag: "IncomeFromInvestmentsInAssociates",
            description:
                "Indtægter af kapitalandele i associerede virksomheder",
        },
        {
            tag: "IncomeFromInvestmentsInJointVentures",
            description: "Indtægter af kapitalandele i joint ventures",
        },
        {
            tag: "IncomeFromOtherLongtermInvestmentsAndReceivables",
            description:
                "Indtægter af andre kapitalandele, værdipapirer og tilgodehavender, der er anlægsaktiver",
        },
        {
            tag: "ExchangeRateAdjustmentsOtherFinanceIncome",
            description: "Valutakursreguleringer indtægt",
        },
        {
            tag: "ExchangeRateAdjustmentsOtherFinanceExpenses",
            description: "Valutakursreguleringer omkostning",
        },
        {
            tag: "OtherFinanceIncomeFromGroupEnterprises",
            description:
                "Andre finansielle indtægter fra tilknyttede virksomheder",
        },
        {
            tag: "OtherFinanceIncome",
            description: "Andre finansielle indtægter",
        },
        {
            tag: "ImpairmentOfFinancialAssets",
            description: "Nedskrivning af finansielle aktiver",
        },
        {
            tag: "FinanceExpensesArisingFromGroupEnterprises",
            description:
                "Finansielle omkostninger der hidrører fra tilknyttede virksomheder",
        },
        {
            tag: "RestOfOtherFinanceExpenses",
            description: "Andre finansielle omkostninger",
        },
        {
            tag: "TaxExpenseOnOrdinaryActivities",
            description: "Skat af ordinært resultat",
        },
        {
            tag: "ExtraordinaryIncome",
            description: "Ekstraordinære indtægter",
        },
        {
            tag: "ExtraordinaryExpenses",
            description: "Ekstraordinære omkostninger",
        },
        {
            tag: "TaxExpenseOnExtraordinaryEvents",
            description: "Skat af ekstraordinært resultat",
        },
        {
            tag: "OtherTaxExpenses",
            description: "Andre skatter",
        },
        {
            tag: "ProfitLossFromDiscontinuedOperations",
            description: "Årets resultat vedrørende ophørende aktiviteter",
        },
        {
            tag: "CurrentTaxExpense",
            description: "Beregnet skat af årets skattepligtige indkomst",
        },
        {
            tag: "AdjustmentsForDeferredTax",
            description: "Årets regulering af udskudt skat",
        },
        {
            tag: "AdjustmentsForCurrentTaxOfPriorPeriod",
            description: "Regulering af skat vedrørende tidligere år",
        },
        {
            tag: "ProposedDividendRecognisedInEquityMember",
            description:
                "Foreslået udbytte indregnet under egenkapitalen (Resultatdisponering)",
        },
        {
            tag: "TransferredToReserveForEntrepreneurialCompanyMember",
            description:
                "Overført til reserve for iværksætterselskab (Resultatdisponering)",
        },
        {
            tag: "ReserveForNetRevaluationAccordingToEquityMethodMember",
            description:
                "Reserve for nettoopskrivning efter indre værdis metode (Resultatdisponering)",
        },
        {
            tag: "DepreciationOfInvestments",
            description: "Afskrivninger på finansielle anlægsaktiver",
        },
        {
            tag: "OtherAdjustmentsOfFinanceIncome",
            description: "Andre reguleringer af finansielle indtægter",
        },
        {
            tag: "OtherAdjustmentsOfFinanceExpenses",
            description: "Andre reguleringer af finansielle omkostninger",
        },
        {
            tag: "ExchangeRateAdjustmentsOfFinanceIncome",
            description: "Valutakursreguleringer, indtægter",
        },
        {
            tag: "ExchangeRateAdjustmentsOfFinanceExpenses",
            description: "Valutakursreguleringer, omkostninger",
        },
        {
            tag: "GainsLossesFromCurrentValueAdjustmentsOfBiologicalAssets",
            description: "Dagsværdiregulering af biologiske aktiver",
        },
        {
            tag: "RefundInJointAssessment",
            description: "Refusion i sambeskatning",
        },
    ],
};

const allTags = [...xbrl.balance, ...xbrl.drift];

export function getExpectedAccountType(xbrlTag) {
    const findByTag = (t) => t.tag === xbrlTag;

    const balanceHit = xbrl.balance.find(findByTag);
    if (balanceHit) return "Balance";

    const driftHit = xbrl.drift.find(findByTag);
    if (driftHit) return "Drift";

    return "Other";
}

/**
 * Lookup the human readable name for "xbrltag".
 * Eg. "AdjustmentsForDeferredTax" => "Årets regulering af udskudt skat".
 * Returns "AdjustmentsForDeferredTax*" if the xbrl-tag is unknown (to signal a problem).
 * @param {*} xbrltag
 */
export const xbrlTranslation = (xbrltag) => {
    const match = allTags.find((tag) => tag.tag === xbrltag);
    return match?.description;
};

export default xbrl;
