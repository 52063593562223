import React, { Component } from 'react';
import RadioOption from '../RadioOption';

class YesNo extends Component {
    constructor (props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    onChange = value => {
        this.setState({ value });
        this.props.onChange(value);
    };

    yesClicked = () => this.onChange(true);

    noClicked = () => this.onChange(false);

    renderRadioButton = ({ checked, label, disabled, onChange }) => {
        return (
            <div
                style={{
                    outline: checked ? '1px solid lightgray' : undefined,
                    background: checked ? 'white' : undefined,
                    pointerEvents: disabled ? 'none' : undefined,
                    display: 'inline-block',
                    borderRadius: '14px',
                    padding: '0.25em 0.5em',
                }}
                children={
                    <RadioOption
                        label={label}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                    />
                }
            />
        );
    };

    render () {
        const { value } = this.state;
        const { yesLabel, noLabel, float, disabled } = this.props;

        const yesChecked = value === true;
        const noChecked = value === false;

        return (
            <div style={{ float, textAlign: float }}>
                <div
                    style={{
                        display: 'inline-block',
                        background: '#edeef0',
                        borderRadius: '14px',
                        padding: '0.25em',
                        border: '1px solid lightgray',
                        cursor: disabled ? 'not-allowed' : undefined,
                    }}
                >
                    {this.props.leftAttachment}

                    {this.renderRadioButton({
                        checked: yesChecked,
                        label: yesLabel || 'Ja',
                        onChange: this.yesClicked,
                        disabled,
                    })}
        
                    <div style={{ display: 'inline-block', width: '0.5em' }} />
        
                    {this.renderRadioButton({
                        checked: noChecked,
                        label: noLabel || 'Nej',
                        onChange: this.noClicked,
                        disabled,
                    })}
                </div>
            </div>
        );
    }
}

export default YesNo;
