import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import Form from 'design/atoms/Form';
import YesNo from 'design/atoms/YesNo';
import FileAttachment from 'design/molecules/FileAttachmentWidget';

class InputToggleWidget extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.bool,
        label: PropTypes.string,
        error: PropTypes.string,
        yesLabel: PropTypes.string,
        noLabel: PropTypes.string,
        isLocked: PropTypes.bool,
        tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    };

    static defaultProps = {
        error: null,
        yesLabel: 'Ja',
        noLabel: 'Nej',
    };

    handleChange = val => {
        const { onChange, supply } = this.props;
        onChange(supply, {
            boolean: val,
        });
    };

    getOptionTexts() {
        const { alternateYes, alternateNo } = this.props;
        return {
            yesLabel: alternateYes ? alternateYes : 'Ja',
            noLabel: alternateNo ? alternateNo : 'Nej',
        };
    }

    renderFileAttachmentButton = () => {
        return (
            <FileAttachment
                {...this.props}
                renderTrigger={({ files }, setOpen) => {
                    let icon;
                    let tooltip;

                    if (files.length === 0) {
                        icon = 'attach';
                        tooltip = 'Vedhæft bilag';
                    } else if (files.length === 1) {
                        icon = 'file';
                        tooltip = '1 bilag vedhæftet';
                    } else {
                        icon = 'copy';
                        tooltip = `${files.length} bilag vedhæftet`;
                    }

                    return (
                        <Popup
                            position='left center'
                            trigger={
                                <span style={{ padding: '0.25em' }}>
                                    <Icon
                                        color='black'
                                        onClick={() => setOpen(true)}
                                        name={icon}
                                        link
                                        circular
                                    />
                                </span>
                            }
                            content={tooltip}
                        />
                    );
                }}
            />
        );
    };

    render() {
        const { defaultValue, label, tooltip, youtubeVideo, error, isLocked } = this.props;
        const { yesLabel, noLabel } = this.getOptionTexts();
        return (
            <Form.Row>
                <Form.LabelField label={label} tooltip={tooltip} youtubeVideo={youtubeVideo} />
                <Form.Field error={error} textAlign='right'>
                    <YesNo
                        value={defaultValue}
                        yesLabel={yesLabel}
                        noLabel={noLabel}
                        disabled={isLocked}
                        onChange={this.handleChange}
                        leftAttachment={this.renderFileAttachmentButton()}
                    />
                </Form.Field>
            </Form.Row>
        );
    }
}

export default InputToggleWidget;
