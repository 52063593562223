import React from 'react';
import { Transition, Checkbox, Message, Icon } from 'semantic-ui-react';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import { useRootUser } from 'util/useUser';


const CreditsConfirmAccountantRole = ({ role, hasConfirmedRole, onConfirmRole }) => {
    const { credits, loading } = useCreditsContext();
    const user = useRootUser();
    
    if (user.roleLocked) {
        return null;
    }

    return (
        <Transition visible={!loading}>
            <ShoppingCartSegment title='Bekræft rolle' icon='linkify'>
                {user.roleLocked ? (
                    <Message info>
                        <Icon name="lock" />
                        Din rolle er låst til <strong>{role}</strong> og kan ikke ændres.
                    </Message>
                ) : (
                    <>
                        <p>
                            <Checkbox 
                                label={{ children: <span>Jeg bekræfter herved at jeg har valgt rollen <strong>{role}</strong>.</span> }}
                                checked={hasConfirmedRole} 
                                onChange={(e, data) => onConfirmRole(data.checked)}
                            />
                        </p>
                        
                        <p>
                            Rollen fastsættes ved dette køb,
                            og vil herefter ikke kunne ændres.
                        </p>
                    </>
                )}


            </ShoppingCartSegment>
        </Transition>
    );
};

export default CreditsConfirmAccountantRole;