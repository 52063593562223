import React, { useEffect, useState } from 'react';
import { Container, Loader, Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import * as fileStorage from 'http/file-storage';

function DownloadFile() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { fileID } = useParams();

    useEffect(() => {
        fileStorage.prepareDownloadLink(fileID)
            .then(link => window.location.href = link)
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [fileID]);

    if (loading) {
        return (
            <Container>
                <Loader
                    active
                    inline='centered'
                    size='huge'
                />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Message content='Filen kan ikke vises...' />
            </Container>
        );
    }

    return null;
}

export default DownloadFile;