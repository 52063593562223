import React from 'react';
import SimpleValue from   '../components/SimpleValue/';
import SimpleLabel from   '../components/SimpleLabel/';
import SimpleBoolean from '../components/SimpleBoolean/';
import SimpleDate from '../components/SimpleDate/';
import ERPConnector from '../../../molecules/ERPConnectorWidget';
import DocumentGenerator from './FactWidgets/documentGenerator';
import ERPData from './FactWidgets/erpData/index';
import CvrOverview from './FactWidgets/cvrOverview';
import TaxCalc from './FactWidgets/taxCalc';
import ExternalInfo from './FactWidgets/externalInformation';
import YearReportData from './FactWidgets/yearReportData';
import XBRLReport from './FactWidgets/xbrlReport';
import Checklist from './FactWidgets/checklist';
import Resource from './FactWidgets/resource';
import ClosingSheet from './FactWidgets/ClosingSheet';
import { JOINT_TAX } from 'flags';


export const TypeFlowContext = 'flowContext';
export const TypeYearReportData = 'yearReportData';
export const TypeCheckList = 'checklist';
export const TypeResource = 'resource';
export const TypeDocGen = 'docGenData.1';
export const TypeRawErpViewer = 'rawErpViewer';
export const DocGenOptionName = 'docgenVariant';
export const DocGenVariantPDF = 'pdf';
export const DocGenVariantIXBRL = 'iXBRL';
export const DocGenVariantDownloadLink = 'downloadLink';
export const DocGenVariantSignflow = 'signflow';

class Fact extends React.Component {

    getFactComponent (dataType) {
		switch (dataType) {
			case 'number':
                return SimpleValue;
            case 'date':
                return SimpleDate;
            case 'enumString':
            case 'string':
                return SimpleLabel;
            case 'bool':
                return SimpleBoolean;
            case TypeDocGen:
            case 'pdf':
                return DocumentGenerator;
            case 'erpConnector':
            case 'erpAuth':
                return ERPConnector;
            case 'accountplans.1':
                return ERPData;
            case 'cvrOverview':
                return CvrOverview;
            case 'TaxData.1':
                return TaxCalc;
            case 'extProductData':
                return ExternalInfo;
            case TypeRawErpViewer:
                return ClosingSheet;
            case TypeYearReportData:
                return YearReportData;
            case 'xbrlReport':
                return XBRLReport;
            case 'checklist':
                return Checklist;
            case 'resource':
                return Resource;
            default:
				throw new Error(`Can't map from '${dataType}' data type to component`);
		}
    }

    createRunActionHandler = () => {
        const { runFieldAction, field } = this.props;
    
        return updateContext => {
            return runFieldAction(field, updateContext);
        };
    };

    isRunning = () => {
        const { field, factsBeingUpdated } = this.props;

        return factsBeingUpdated.has(field?.id);
    };

    render () {
        const { field, values, extractValue, validationResult, getValidationMessage, executionErrors, runFieldAction, ...otherProps } = this.props;
        const error = getValidationMessage(validationResult, field.id);
        const Component = this.getFactComponent(field.dataType);
        const value = extractValue(values[field.id]);
        const executionError = (executionErrors || {})[field.id];


        return (
            <Component
                key={field.id}
                {...field}
                error={error}
                value={value}
                values={values}
                executionError={executionError}
                extractValue={extractValue}
                onChange={this.props.onChange}
                location={this.props.location}
                runAction={this.createRunActionHandler()}
                isRunning={this.isRunning()}
                {...otherProps}
            />
        );
    }
}

export default Fact;
