import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Message } from 'semantic-ui-react';
import { ESG_OPEN_FOR_ALL } from 'flags';
import ProductPage from 'design/molecules/ProductPage';
import AccountantOverview from 'design/molecules/AccountantOverview';
import AccountantRoleSelection from 'design/molecules/AccountantRoleSelection';
import PageHeader from 'design/atoms/PageHeader';
import useUser from 'util/useUser';
import getProducts from 'util/getProducts';
import Stepper from 'design/atoms/Stepper';
import Menu from 'design/molecules/Menu';
import { getBalance } from 'http/credits';
import { getClientInfo } from 'http/productEngine';
import { SPLIT_BOOKKEEPER_ACCOUNTANT } from 'flags';
import set from 'lodash.set';

const getPathToRelevantProduct = (user, esgIsActive) => {
    if (!user.isCompany()) {
        return '/service/boligudlejning';
    }

    if (user.isSelskab()) {
        return '/service/aarsrapport_b';
    }
    
    if (user.isInteressentskab()) {
        return '/service/aarsrapport_is';
    }

    if (user.isVirksomhed()) {
        return '/service/aarsrapport_a';
    }

    if (ESG_OPEN_FOR_ALL() && esgIsActive) {
        // any other company type should see the esg product
        return '/service/esg'; 
    }

    return null; // no relevant product found...
}

const LoadingProductPage = () => <ProductPage subHeader={<PageHeader loading />} loading />;

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [selectedStepIndex, setSelectedStepIndex] = useState(0);
    const [esgIsActive, setEsgIsActive] = useState(false);
    const [clients, setClients] = useState(null);
    const [creditBalance, setCreditBalance] = useState(null);
    const [roleSelectedInChild, setRoleSelectedInChild] = useState(false);

    const user = useUser();
    const history = useHistory();
    
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const products = await getProducts();
                const esgIsActive = products.some(product => product.id === 'esg');
                setEsgIsActive(esgIsActive);

                const clientData = await getClientInfo();
                const creditBalance = await getBalance();

                setClients(clientData);
                setCreditBalance(creditBalance);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);



    if (loading) {
        return <LoadingProductPage />;
    }

    if (user.isAccountant() && !user.impersonation) {
        const isBookkeeper = (user.roles.indexOf("bookkeeper") > -1);
        const isAssistance = (user.roles.indexOf("assistance") > -1);
        const hasSelectedRole = isBookkeeper || isAssistance;
        const roleLocked = user.roleLocked === true;

        if (roleLocked && (isBookkeeper || isAssistance)) {
            return (
                <ProductPage
                    children={<AccountantOverview user={user} />}
                    subHeader={<PageHeader content='Klientlister' icon='users' />}
                />
            );
        }
        
        if (SPLIT_BOOKKEEPER_ACCOUNTANT() && !roleLocked) {
            const effectiveHasSelectedRole = hasSelectedRole || roleSelectedInChild;
            
            if (!effectiveHasSelectedRole && selectedStepIndex !== 0) {
                setSelectedStepIndex(0);
            } 
            
            const steps = [
                {
                    title: 'Vælg rolle',
                    icon: 'settings',
                    idx: 0,
                    isSelected: selectedStepIndex === 0,
                },
                {
                    title: 'Klientliste',
                    icon: 'users',
                    idx: 1,
                    isSelected: selectedStepIndex === 1,
                }
            ].map((step, idx) => ({
                ...step,
                isSelected: selectedStepIndex === idx, 
                onSelected: () => {
                    if (idx === 1 && !effectiveHasSelectedRole) {
                        return;
                    }
                    setSelectedStepIndex(idx);
                },
            }));
            
        
            const renderContent = () => {
                if (selectedStepIndex === 0) {
                    return (
                        <AccountantRoleSelection 
                            user={user} 
                            nextStep={() => {
                                if (effectiveHasSelectedRole && selectedStepIndex === 0) {
                                    setSelectedStepIndex(1);
                                }
                            }}
                            onRoleSelected={(role) => {
                                setRoleSelectedInChild(true);
                            }}
                        />
                    );
                } else {
                    return <AccountantOverview user={user} />;
                }
            };
            
            
            return (
                <div>
                    <Menu />
                    <PageHeader content="Klientlister" icon="users" />
                    <Stepper
                        selectedStepIndex={selectedStepIndex}
                        steps={steps.map((step, idx) => ({
                            ...step,
                            isCompleted: selectedStepIndex > idx,
                            bgColorCompleted: '#edf9f1',
                            bgColorSelected: '#d3f1de',
                            borderColor: '#46C476',
                        }))}
                        height={50}
                        offset={10}
                    />
                    <div style={{ marginTop: '20px' }}>{renderContent()}</div>
                </div>
            );
        }
        
        return (
            <ProductPage
                children={<AccountantOverview user={user} />}
                subHeader={<PageHeader content='Klientlister' icon='users' />}
            />
        );
    }

    const productRedirectPath = getPathToRelevantProduct(user, esgIsActive);
    if (productRedirectPath) {
        history.push(productRedirectPath);
        
        return <LoadingProductPage />;
    }

    return (
        <ProductPage subHeader={<PageHeader />}>
            <Message>
                <Icon name='info circle' />
                Der findes på nuværende ikke tidspunkt ikke nogen produkter
                for virksomheder af typen <strong>{user.businessForm}</strong>.
            </Message>
        </ProductPage>
    );
};

export default Home;