import React from 'react';
import { Button, Header, Icon, Menu, Modal, Popup } from 'semantic-ui-react';
import { CLOSING_SHEET_QUESTIONS } from 'flags';
import { formatDateTime } from 'util/format/DateTime';
import EntriesViewer from './EntriesViewer';
import AttachmentsViewer from './AttachmentsViewer';
import CommentsViewer from './CommentsViewer';
import QuestionsViewer from './QuestionsViewer';

export const documentationOptionsCategories = {
    attachments: {
        title: 'Bilag',
        icon: 'attach',
        Component: AttachmentsViewer,
        isVisible: () => true,
        hasActivity: props => props.accountInfo.attachedFiles?.length > 0,
    },
    notes: {
        title: 'Noter',
        icon: 'sticky note',
        Component: CommentsViewer,
        isVisible: () => true,
        hasActivity: props => props.accountInfo.comments?.length > 0,
    },
    questions: {
        title: 'Arbejdshandlinger',
        icon: 'question circle',
        Component: QuestionsViewer,
        isVisible: account => CLOSING_SHEET_QUESTIONS() && account.associatedQuestionsGroup?.questionsAndAnswers?.length > 0,
        hasActivity: props => {
            const unansweredQuestions = props.account?.associatedQuestionsGroup?.countUnansweredQuestions() ?? 0;
            return unansweredQuestions === 0;
        },
    },
};

const DocumentationViewer = ({ ...props }) => {
    const subTab = (
        props.accountMenuState?.selectedSubTab ||
        documentationOptionsCategories.attachments
    );

    const Component = subTab.Component;

    return (
        <>
            <Modal.Content style={{ paddingTop: '0.5em', paddingBottom: 0 }}>
                <Menu secondary pointing>
                    {Object.values(documentationOptionsCategories).map(category => {
                        if (!category.isVisible(props.account)) {
                            return null;
                        }
                        
                        return (
                            <Menu.Item
                                active={category === subTab}
                                onClick={() => props.setAccountMenuState({ ...props.accountMenuState, selectedSubTab: category })}
                                content={category.title}
                                icon={
                                    <Icon
                                        name={category.icon}
                                        color={category.hasActivity(props) ? 'green' : 'black'}
                                    />
                                }
                            />
                        );
                    })}
                </Menu>
            </Modal.Content>
            <Component {...props} />
        </>
    );
};

export const accountOptionsCategories = {
    postings: {
        title: () => 'Posteringer',
        icon: 'list',
        Component: EntriesViewer,
    },
    documentation: {
        title: ({ auditorsDocumentationInfo }) => auditorsDocumentationInfo?.auditorsDocumentationIsActive ? 'Dokumentation' : 'Bilag og noter',
        icon: 'file',
        Component: DocumentationViewer,
    },
};

const AccountOptions = ({
    header,
    onClose,
    accountMenuState,
    setAccountMenuState,
    showDecimalAccounts,
    currentYearAmount,
    unroundedCurrentYearAmount,
    auditorsDocumentationInfo,
    ...props
}) => {
    const accountOptionMenuItems = Object.values(accountOptionsCategories);

    const renderMenu = () => (
        <Menu
            tabular
            attached='top'
            style={{
                marginBottom: '-1px',
                marginTop: '1em',
            }}
        >
            {accountOptionMenuItems.map(menuItem => {
                const active = menuItem === accountMenuState.selectedMainTab;
                return (
                    <Menu.Item
                        content={menuItem.title({ auditorsDocumentationInfo })}
                        active={active}
                        link={!active}
                        onClick={() => setAccountMenuState({ ...accountMenuState, selectedMainTab: menuItem })}
                    />
                );
            })}
        </Menu>
    );

    const renderApprovalButton = () => {
        const { associatedQuestionsGroup } = props.account;
        if (!associatedQuestionsGroup) {
            return null;
        }

        if (!auditorsDocumentationInfo.loggedInUserIsConfirmingUser) {
            return null;
        }

        const prerequisitesForEnablingConfirmButton = [];

        const uncheckedRelevantAccounts = associatedQuestionsGroup.countRelevantAccountsThatAreUnchecked();
        if (uncheckedRelevantAccounts > 0) {
            prerequisitesForEnablingConfirmButton.push('Alle konti i gruppen er afstemt')
        }
        
        const amountOfUnansweredQuestions = associatedQuestionsGroup.countUnansweredQuestions();
        if (amountOfUnansweredQuestions > 0) {
            prerequisitesForEnablingConfirmButton.push('Alle spørgsmål er besvaret');
        }

        const { isApprovedByConfirmingLogin, confirmingLoginApprovalTimestamp } = associatedQuestionsGroup.state || {};

        let buttonTooltip;
        if (isApprovedByConfirmingLogin) {
            buttonTooltip = `Godkendt d. ${formatDateTime(confirmingLoginApprovalTimestamp)}`
        } else if (prerequisitesForEnablingConfirmButton.length > 0) {
            buttonTooltip = (
                <div>
                    Du kan først godkende når:
                    {prerequisitesForEnablingConfirmButton.map(message => {
                        return <div>&bull; {message}</div>;
                    })}
                </div>
            );
        }

        const buttonEnabled = isApprovedByConfirmingLogin || prerequisitesForEnablingConfirmButton.length === 0;

        return (
            <Popup
                basic
                position='left center'
                disabled={!buttonTooltip}
                content={buttonTooltip}
                trigger={(
                    <div>
                        <Button
                            icon={isApprovedByConfirmingLogin ? 'check' : undefined}
                            onClick={() => props.toggleQuestionGroupChecked(associatedQuestionsGroup.groupID)}
                            content={isApprovedByConfirmingLogin ? `Godkendt` : 'Godkend'}
                            basic={isApprovedByConfirmingLogin}
                            disabled={!buttonEnabled}
                            primary
                        />
                    </div>
                )}
            />
        );

    };

    const groupCategory = props?.account?.associatedQuestionsGroup?.groupData?.title;
    let headerToUse;
    let subheaderToUse;
    if (groupCategory) {
        headerToUse = groupCategory;
        subheaderToUse = header;
    } else {
        headerToUse = header;
    }
    
    const Component = accountMenuState.selectedMainTab?.Component;

    return (
        <Modal open onClose={onClose} closeIcon centered={false} closeOnDimmerClick={false}>
            <Modal.Header style={{ paddingBottom: 0 }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Header
                            size='small'
                            content={headerToUse}
                            subheader={subheaderToUse}
                        />
                    </div>
                    {renderApprovalButton()}
                </div>
                {renderMenu()}
            </Modal.Header>
            {Component && (
                <Component
                    {...props}
                    showDecimalAccounts={showDecimalAccounts}
                    currentYearAmount={props.account.currentYearAmount}
                    unroundedCurrentYearAmount={props.account.unroundedCurrentYearAmount}
                    accountMenuState={accountMenuState}
                    setAccountMenuState={setAccountMenuState}
                />
            )}
        </Modal>
    );
};

export default AccountOptions;