import React, { useState, useEffect } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import { getReportingEntities, registerClient } from 'http/accounts';
import { setChosenUser } from 'util/token';
import { getCompanyRelations } from 'http/cvr';
import { beginImpersonation } from 'util/impersonation';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOwnerShipEntity } from '../../../http/accounts';
import { getClientInfo } from 'http/productEngine';
import { formatDate } from 'util/format/DateTime';
import styles from './OwnerShipTree.module.scss';
import Tooltip from 'design/atoms/Tooltip';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';


/* 
  The goal of this component is the render a ownership tree for the user
  The tree gives basic functionality for ease of creating related entities
  and seeing relations between the companies.
*/
const RenderOwnerShipTree = ({ userData, redirectURL }) => {
  const history = useHistory();
  const [entities, setEntities] = useState([]);
  const [clients, setClients] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);
  const daughterCompanies = ownerShip.daughterCompanies || [];
  const legalOwners = ownerShip.owners || [];
  const realOwners = ownerShip.realOwner || [];
  const toolTipTextRealOwners = `
  Reelle ejere er personer, der direkte eller indirekte:
  har mere end 25 % ejerskab eller stemmerettigheder eller
  har anden afgørende kontrol, fx vetoret, ret til at udpege bestyrelsesmedlemmer el.lign.

  Alt er i relation til det selskab, du er logget ind på.
  `;


  const toolTipTextOwners = `
  Legale ejere er personer eller virksomheder, der direkte:
  har mindst 5 % ejerskab eller stemmerettigheder

  Alt er i relation til det selskab, du er logget ind på.
  `;

  const toolTipTextDaughter = `
  Et datterselskab er et selskab, der ejes af et andet selskab, kendt som moder- eller holdingselskabet.

  Alt er i relation til det selskab, du er logget ind på.
  `;


  let noOwnersText = `Virksomheden har ikke reelle ejere, og ledelsen er indsat som reelle ejere.`;

  /* Use effect to get the relations and clients is impersonating */
  useEffect(() => {
    const fetchRelations = async () => {
      const relations = await getCompanyRelations(userData.cvr);
      setOwnerShip(relations);
    };

    const fetchEntities = async () => {
      const entities = await getReportingEntities();
      setEntities(entities);
    };

    const fetchClients = async () => {
      const clients = await getClientInfo();
      setClients(clients);
    };
    
    if (userData.impersonation) {
      fetchClients();
    } else {
      fetchEntities();
    }
    fetchRelations();
  }, [userData]);

  const changeEntity = (id) => {

    if (userData.impersonation) {
      beginImpersonation(id);
      history.push('/');
    }

    else {
      setChosenUser(id);
      refreshTokens();
      history.push('/');
    }
  };

  const changeToProduct = () => { 
    history.push(redirectURL);
  };

  const registerClientWithData = async (data) => {
    await registerClient(data);
  };

  /* If the user wants to create the related entites 
     We call accounts here to create the entity and then change to that entity
     If the user is impersonating we create a client instead
  */
  const createAnotherEntity = async (company) => {
    let defaultMessage = `${company.name} er blevet oprettet. Du vil blive sendt tilbage til forsiden om lidt.`;
  
    const companyData = {
      cvr: company.CVR,
      OwnershipFraction: company.OwnershipFraction,
    };
  
    if (!userData.impersonation) {
      try {
        await createOwnerShipEntity(userData.login.loginEmail, company);
        const updatedEntities = await getReportingEntities();
        const newEntity = updatedEntities.find(entity => entity.displayName === company.name);
        const newEntityId = newEntity ? newEntity.uid : null;
        
        toast.success(defaultMessage);
        if (newEntityId) {
            setTimeout(() => {
              changeEntity(newEntityId);
            }, 1000);
        }
      } catch (error) {
        toast.error(`Der opstod en fejl, ${error}`);
      }
    } else {
      try {
        await registerClientWithData(companyData);
        const updatedClients = await getClientInfo();
        const clientsArray = Object.values(updatedClients).flatMap(client => Object.values(client));
        const newClient = clientsArray.find(client => client.companyName === company.name);
        const newClientId = newClient ? newClient.id : null;
  
        toast.success(defaultMessage);
        if (newClientId) {
          setTimeout(() => {
            changeEntity(newClientId);
          }, 1000);
        }
      } catch (error) {
        toast.error(`Der opstod en fejl, ${error}`);
      }
    }
  };


  /* The button to either create a related company or change to that entity  */
  const furtherActionButton = (company, isCurrentUser) => {
    let isEntity;
    let entityId;

    if (userData.impersonation) {
      const clientsArray = Object.values(clients).flatMap(client => Object.values(client));
      isEntity = clientsArray.find(client => client.companyName === company.name);
      entityId = isEntity ? isEntity.id : null;
    } 
    else {
      isEntity = entities.find(entity => entity.displayName === company.name);
      entityId = isEntity ? isEntity.uid : null;
    }

    if (!isEntity) {
      return (
        <Button
          className={isCurrentUser ? styles.activeBtn : ''}
          onClick={() => createAnotherEntity(company, entityId)}
          size='small'
        >
          Opret selskab
        </Button>
      );
    }

    return (
      <Button
        className={isCurrentUser ? styles.activeBtn : ''}
        onClick={() => changeEntity(entityId)}
        size='small'
      >
        Gå til selskab
      </Button>
    );
  };


  /* Maybe a bit of a bad name but renders the legal owners  */
  const renderOwnersList = (owners, title, toolTipText) => (
    <>
      <Segment>
        <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center', margin:'0'}}>
          <Header size='medium'>{title}</Header>
          <Tooltip data={toolTipText} position='top center'/>
        </div>
        {owners.length < 1 ? (
          <Segment >
          <Header size='medium'>{noOwnersText}</Header>
        </Segment>
        ) : (
          owners.map((owner, index) => (
            <Segment key={index}>
              <Header>{owner.name}</Header>
              <Header.Subheader>
                <span className='date'>Ejer {(owner.ownershipFraction * 100).toFixed(2)}% af <b style={{ color: 'black' }}>{userData.companyName}</b></span>
                <br></br>
                <span>Gyldig fra {formatDate(owner.startDate)}</span>
              </Header.Subheader>
            </Segment>
          ))
        )}
      </Segment>
    </>
  );


/* Makes the company list items (Daughter companies and owner companies)
   Only creates a fake company with the logged in user if we have any relations at all
*/
const renderCompanyList = (companies, title, toolTipText) => {
  const hasAnyRelations = daughterCompanies.length > 0 || legalOwners.length > 0;
  
  return (
    <>
      <Segment>
        <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
        <Header style={{margin:'0.5rem 0'}} size='medium' textAlign='left'>{title}</Header>
        <Tooltip data={toolTipText} position='top center'/>
        </div>
        {companies && companies.length > 0 ? (
          companies.map((company, index) => {
            return (
              <Segment
                key={index}
                className={styles.default}
              >
                <Header
                  className={styles.defaultHeader}
                >
                  {company.name}
                  <Header.Subheader
                    className={styles.defaultSubheader}
                  >
                    Cvr: <span style={{ fontWeight: 'bolder' }}>{company.CVR || null}</span>
                  </Header.Subheader>
                </Header>
                {furtherActionButton(company, false)}
              </Segment>
            );
          })
        ) : hasAnyRelations ? (
          <Segment className={styles.activeCompany}>
            <div style={{display:'flex', justifyContent: 'space-between', flexDirection:'column'}}>
              <Header className={styles.activeHeader}>
                {userData.companyName}
              </Header>
              <Header.Subheader className={styles.activeSubheader}>
                Cvr: <span style={{ fontWeight: 'bolder' }}>{userData.cvr || null}</span>
              </Header.Subheader>
            </div>
            <Button onClick={() => changeToProduct()} inverted color='white'>
              Gå til produkt
            </Button>
          </Segment>
        ) : (
          <Segment>
            <Header size='small'>Ingen relationer fundet</Header>
          </Segment>
        )}
      </Segment>
    </>
  );
};

  return (
    <Segment basic>
      {renderOwnersList(realOwners, 'Reelle ejere', toolTipTextRealOwners)}
      {renderCompanyList(legalOwners, 'Legale ejere', toolTipTextOwners)}
      {renderCompanyList(daughterCompanies, 'Datterselskaber', toolTipTextDaughter)}
    </Segment>
  );

};

export default RenderOwnerShipTree;

