import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Form from 'design/atoms/Form';
import MultipleChoice from 'design/atoms/MultipleChoice';

class InputRadioGroupWidget extends React.Component {
	constructor (props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = enumString => {
		const { onChange, supply } = this.props;
		onChange(supply, { enumString });
	};

    static propTypes = {
        label: PropTypes.string.isRequired,
        isLocked: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            optionText: PropTypes.string.isRequired,
            optionValue: PropTypes.string.isRequired,
            tooltip: PropTypes.string,
        })),
    }

	static defaultProps = {
		options: [],
	};

	optionIsDisabled = opt => {
		const { values, isLocked } = this.props;
		const ruleReferences = opt?.ruleReferences || [];

		const result = (isDisabled, message) => ({
			isDisabled,
			message,
		});

		// product is locked => disabled
		if (isLocked) {
			return result(true);
		}

		// all rules validated => enabled
		for (let { reference, advisoryMessage } of ruleReferences) {
			const lookup = get(values, `${reference}.value.boolean`, false);
			if (!lookup) {
				return result(true, advisoryMessage);
			}
		}

		return result(false);
	};

	prepareOptions = () => {
		const { options } = this.props;

		return options.map(opt => {
			const { optionText, optionValue, optionTooltip } = opt;
			const { isDisabled, message } = this.optionIsDisabled(opt);

			return {
				text: optionText,
				key: optionValue,
				value: optionValue,
				tooltip: optionTooltip,
				disabled: isDisabled,
				disabledTooltip: message,
			};
		});
	};

	renderQuestionWidget = () => {
		if (this.props.renderAsDropdown) {
			return (
				<Dropdown
					selection
					fluid
					search
					defaultValue={this.props.defaultValue}
					onChange={(_, { value }) => this.handleChange(value)}
					options={this.prepareOptions().map(option => {
						return {
							key: option.key,
							text: option.text,
							value: option.value,
							description: option.tooltip,
							disabled: option.disabled,
						};
					})}
				/>
			);
		}

		return (
			<MultipleChoice
				direction={this.props.styleOptions.direction}
				value={this.props.defaultValue}
				onChange={this.handleChange}
				options={this.prepareOptions()}
			/>
		);
	};

	render () {
		const { label, tooltip, youtubeVideo, error } = this.props;
		return (
			<Form.Row direction={this.props.styleOptions.direction}>
				<Form.LabelField
					label={label}
					generalTooltip={tooltip}
					generalVideoTooltip={youtubeVideo}
				/>
				<Form.Field error={error} textAlign='right'>
					{this.renderQuestionWidget()}
				</Form.Field>
			</Form.Row>
		);
	}
}

export default InputRadioGroupWidget;
