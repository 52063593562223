import { toUpper } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { getCompanyRelations } from 'http/cvr';
import { getClientInfo } from 'http/productEngine';


const RelationDropdown = ({setSelectedOwnerCompanies, setSelectedDaughterCompanies, showDropdown, onChange, data }) => {
  const [ownerShip, setOwnerShip] = useState([]);
  const [ownerCompanies, setOwnerCompanies] = useState([]);
  const [daughterCompanies, setDaughterCompanies] = useState([]);
  const [client, setClients] = useState([]);

  /* Small funcs to write labels  */
  const getPlaceholderLabel = (type) => toUpper(`${type}selskaber`);
  const getLabel = (type) => `${type}selskaber`;

  
  /* Collect relation information from cvr service  */
  useEffect(() => {
    const fetchRelations = async () => {
      const relations = await getCompanyRelations(data.value);
      setOwnerShip(relations);
    };

    const fetchClients = async () => {
      const clients = await getClientInfo();
      const filterClients = Object.values(clients.clientDetails);
      setClients(filterClients);
    };

    fetchClients();
    fetchRelations();
  }, [data]);


  /* Structure data for dropdown, we also check if the related company is already in the client list if thats the case dont show them   */
    useEffect(() => {
        const isPresent = (companies) => companies.find((company) => String(company.CVR) === data.value);
        
        const filterExistingClients = (company) => {
          return !client.some(clientItem => 
            String(clientItem.cvr) === String(company.CVR) || 
            clientItem.companyName === company.name
          );
        };

        if (ownerShip.owners && !isPresent(ownerShip.owners)) {
          const filteredOwners = ownerShip.owners.filter(filterExistingClients);
          
          setOwnerCompanies(
            filteredOwners.map((company) => ({
              key: String(company.CVR),
              text: company.name,
              value: {
                cvr: String(company.CVR),
                name: company.name,
              },
            }))
          );
        }

        if (ownerShip.daughterCompanies && !isPresent(ownerShip.daughterCompanies)) {
          const filteredDaughters = ownerShip.daughterCompanies.filter(filterExistingClients);
          
          setDaughterCompanies(
            filteredDaughters.map((company) => ({
              key: String(company.CVR),
              text: company.name,
              value: {
                cvr: String(company.CVR),
                name: company.name,
              },
            }))
          );
        }
      }, [ownerShip, data, client]); 


/* Make dropdowns both states are passed in both and 
   data is structured to ensure they are the same */
  return (
    <>
      {showDropdown && ownerCompanies.length > 0 && (
       <Form.Field style={{ marginTop: '1rem' }}>
       <label>{getLabel('Ejer')}</label>
       <Dropdown
         multiple
         selection
         fluid
         options={ownerCompanies}
         placeholder={getPlaceholderLabel('ejer')}
         onChange={(_, { value }) => {
           const owners = value.map(v => ({ cvr: v.cvr, name: v.name }));
           setSelectedOwnerCompanies(owners);
           onChange &&
             onChange({
               cvr: data.value,
               ownerShip,
               selectedOwnerCompanies: owners,
               selectedDaughterCompanies: daughterCompanies.map(d => ({ cvr: d.value.cvr, name: d.value.name })),
               valid: true,
             });
         }}
       />
     </Form.Field>
      )}

      {showDropdown && daughterCompanies.length > 0 && (
        <Form.Field>
          <label>{getLabel('Datter')}</label>
          <Dropdown
            multiple
            selection
            fluid
            options={daughterCompanies}
            placeholder={getPlaceholderLabel('datter')}
            onChange={(_, { value }) => {
              const daughters = value.map(v => ({ cvr: v.cvr, name: v.name }));
              setSelectedDaughterCompanies(daughters);
              onChange &&
                onChange({
                  cvr: data.value,
                  ownerShip,
                  selectedOwnerCompanies: ownerCompanies.map(o => ({ cvr: o.value.cvr, name: o.value.name })),
                  selectedDaughterCompanies: daughters,
                  valid: true,
                });
            }}
          />
        </Form.Field>
      )}
    </>
  );
};

export default RelationDropdown;